import { PageSection } from '../../templates';
import Page from '../../templates/Page';
import React from 'react';


export default function SubcoolEventTableRefresh() {

  return (
    <Page
      hideHeader
      fullWidth
    >
      <PageSection
        title={"Manage Subcool Events"}
        onBack={() => {}}
        direction="row"
        headerSx={{ mt: 1 }}
      ></PageSection>
    </Page>
  );
}