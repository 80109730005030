/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonGroup, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import RefreshIcon from '@mui/icons-material/Refresh';
import Search from '@mui/icons-material/Search';
import { MAutoComplete, MButton, MDatePicker, MFormGroup } from '../../@material-extend';
import { LayoutContext, LookupContext, DeliveryTicketContext } from '../../../contexts';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { dateHelper } from '../../../helpers/dateHelper';
import { useNavigateTo } from '../../../helpers';
import { useSearchParams } from 'react-router-dom';

export default function DeliveryTicketTable() {
  /* ---------------------------- Navigation Hooks ---------------------------- */
  const [searchParams, setSearchParams] = useSearchParams();
  const [, navigateTo] = useNavigateTo();

  /* ----------------------------- Context Providers ----------------------------- */
  const lookup = useContext(LookupContext);
  const {
    windowDimensions: { height },
    constants,
    setPageState,
    pageState,
    isLoading,
    handleError,
  } = useContext(LayoutContext);
  const {
    entities,
    queryHandler
  } = useContext(DeliveryTicketContext);

  /**
   * Site Name Comparator
   * useCallback to prevent this from getting redefined on every rerender
   */
  const siteNameComparator = React.useCallback((id1, id2) =>
    lookup.byValue('site_hierarchy', id1).label.localeCompare(lookup.byValue('site_hierarchy', id2).label), [lookup]);


  /* --------------------------- React State Values --------------------------- */

  const [tickets, setTickets] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [sortModel, setSortModel] = React.useState([{
    field: 'delivery_ticket_created_at',
    sort: 'desc',
  }]);
  const [startDateProps, setStartDateProps] = useState({
    minDate: undefined,
    maxDate: dateHelper.getToday(),
  });
  const [endDateProps, setEndDateProps] = useState({
    minDate: queryHandler.values.start_date || dateHelper.getToday(),
    maxDate: dateHelper.getBackDays(dateHelper.getToday(), -1),
  });

  /* -------------------------- Memoized State Values ------------------------- */

  /** Memoized options for the site dropdown */
  const options = React.useMemo(() => {
    if (!lookup?.values?.site_hierarchy.length) return []
    return lookup.values.site_hierarchy.map(site => ({ label: site.label, id: site.site_uuid }))
  }, [lookup.values.site_hierarchy])

  /** Memoized columns */
  const columns = React.useMemo(() => [
    {
      field: "actions",
      disableColumnMenu: true,
      disableReorder: true,
      headerName: '',
      widthMultiplier: 3,

      renderCell: (params) => (<ButtonGroup
        color="inherit"
      >
        <MButton
          onClick={() => navigateTo(routeConfig.delivery_ticket_view, { delivery_ticket_uuid: params.id })}
          size="small"
          aria-label="view"
          children="View Ticket"
          tooltip="View Delivery Ticket"
        />

      </ButtonGroup>

      ),
    },
    {
      field: 'jira_ticket_number',
      description: 'Jira Ticket Number',
      headerName: 'Jira Ticket',
      widthMultiplier: 2,
    },
    {
      field: 'site_uuid',
      description: 'Site',
      headerName: 'Site',
      hide: false,
      widthMultiplier: 5,
      sortComparator: siteNameComparator,
      renderCell: (params) => {
        var site_name = lookup.byValue('site_hierarchy', params.value).label
        if (typeof site_name === "undefined") {
          site_name = 'Retired'
        }
        return (
          site_name
        )
      },
    },
    {
      field: 'supplier_name',
      description: 'Supplier Name',
      headerName: 'Supplier',
      widthMultiplier: 3,
    },
    {
      field: 'delivery_ticket_status',
      description: 'Delivery Ticket Status',
      headerName: 'Status',
      widthMultiplier: 2,
    },
    {
      field: 'issue_resolution',
      description: 'Resolution',
      headerName: 'Resolution',
      widthMultiplier: 2,
    },
    {
      field: 'confirmed_delivery_window_end',
      description: 'Confirmed Delivery Window End',
      headerName: 'Confirmed End',
      widthMultiplier: 3.5,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatLocalDateTimeShort(params.value) : "-"
        )
      },
    },
    {
      field: 'requested_delivery_window_end',
      description: 'Requested Delivery Window End',
      headerName: 'Requested End',
      widthMultiplier: 3.5,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatLocalDateTimeShort(params.value) : "-"
        )
      },
    },
    {
      field: 'delivery_ticket_created_at',
      description: 'Delivery Ticket Created',
      headerName: 'Created',
      widthMultiplier: 3.5,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatLocalDateTimeShort(params.value) : "-"
        )
      },
    },
    {
      field: 'delivery_recognized_by_seeq',
      description: 'Recognized By Seeq',
      headerName: 'Recognized by Seeq',
      widthMultiplier: 3,
      renderCell: (params) => {
        return (
          (params.value == null) ? "-" : params.value ? "Yes" : "No"
        )
      },
    },
  ].map(fullCol => {
    const { widthMultiplier, ...col } = fullCol;
    return {
      ...col,
      width: 50 * widthMultiplier,
    }
  }), [lookup, siteNameComparator]);


  /* ----------------------- Use Effect Lifecycle Hooks ----------------------- */

  /** Filter out cancelled tickets */
  React.useEffect(() => {
      const filtered = entities.filter(ticket => ticket.issue_resolution !== 'Cancelled');
      setTickets(filtered);
  }, [entities]);

  /** Fetch data on initial load */
  React.useEffect(() => {
    queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    queryHandler.values.start_date = dateHelper.getStartDateFromUrlOrDefault(searchParams)
    queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)
    queryHandler.fetch().then(res => {
      setSortModel([{ field: 'delivery_ticket_created_at', sort: 'desc' }]);
    }, setSortModel)
    .catch(error => {
      const errorMessage = "Unable to get Delivery Tickets: " + error 
      handleError(errorMessage);
    });
    setPageState({ ...pageState, dense: true })
    return () => {
      setPageState({
        ...pageState,
        dense: false
      });
    };
  }, [])

  /** Update the date range based on the query params and update URL params */
  React.useEffect(() => {
    setStartDateProps(prev => ({
      ...prev,
      maxDate: queryHandler.values.end_date || dateHelper.getToday(),
    }))
    setEndDateProps(prev => ({
      ...prev,
      minDate: queryHandler.values.start_date || dateHelper.getToday(),
    }))
    setSearchParams({
      ...searchParams,
      site_uuid: queryHandler.values?.site_uuid || "",
      start_date: queryHandler.values.start_date || dateHelper.getToday(),
      end_date: queryHandler.values.end_date || dateHelper.getToday()
    })
  }, [queryHandler.values.start_date, queryHandler.values.end_date, queryHandler.values.site_uuid])


  const refreshData = () => {
    // TODO: can this be done with useLocation() ??
    setTimeout(() => {
      navigateTo(routeConfig.delivery_ticket_table_refresh)
    });
    setTimeout(() => {
      navigateTo(routeConfig.delivery_ticket_table)
    });
  }

  const applyFilter = () => {
    queryHandler.fetch()
    .then(result => {
      return result;
    })
    .catch(error => {
      const errorMessage = "Unable to get Delivery Tickets: " + error 
      handleError(errorMessage);
    });
  }

  return (
    <Page
      hideHeader
      fullWidth
    >
      {/* ----------------------------- Filter Section ---------------------------- */}
      <PageSection
        title={"Manage Delivery Tickets"}
        direction="row"
        headerSx={{ mt: 1 }}
        onBack={() => navigateTo(routeConfig.dashboard)}
        onBackTooltip={"Back to Dashboard"}
      >
        <Grid container
          item
          spacing={5}
          sx={{ pr: 1 }}
          xs={12}
          md={6}
          lg={8}
        >
          {/* ------------------------------ Date Filter ------------------------------ */}
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Date Range"}
          >
            <MDatePicker
              {...startDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values.start_date}
              label={"Start Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("start_date")}
            />
            <MDatePicker
              {...endDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values["end_date"]}
              placeholder="End Date"
              label={"End Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("end_date")}
            />
          </MFormGroup>

          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Site"}
          >
            <MAutoComplete
              GridProps={{
                xs: 12,
                lg: 12,
              }}
              options={options}
              value={queryHandler.values["site_uuid"]}
              onChange={queryHandler.createValueChangeHandler("site_uuid")}
              includeAll
              name="site_uuid"
            />
          </MFormGroup>

        </Grid>
        {/* ------------------------------ Action Items ------------------------------ */}

        <MFormGroup
          GridProps={{ xs: 12, md: 6, lg: 4, spacing: 1, }}
          labelSx={{ mb: 1 }}
          title={`Actions`}

        >

          <MButton
            GridProps={{ xs: 6, md: 8, }}
            sx={{ mt: 1, }}
            variant="contained"
            size={"large"}
            color="primary"
            onClick={applyFilter}
            children="Apply Filter"
            startIcon={<Search />}
            disabled={!queryHandler.isDirty}
          />
          <MButton
            GridProps={{
              xs: 6,
              md: 4,
            }}
            sx={{ mt: 0.6, }}
            size={"large"}
            color="primary"
            onClick={() => refreshData()}
            children="Refresh Data"
            startIcon={<RefreshIcon />}
            tooltip="Refresh the data"
          />
        </MFormGroup>
      </PageSection>
      <div style={{
        display: 'flex',
        width: "100%",
        overflowX: "auto",
        height: height - constants.APP_BAR_HEIGHT
      }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={!!isLoading}
            rows={tickets}
            columns={columns}
            columnBuffer={25}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={setPageSize}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />

        </div>
      </div>
    </Page >
  );
}