/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import { MButton, MDivider, MForm, MFormTextField, MList } from '../../@material-extend';
import { LookupContext, SubcoolEventContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import PageText from '../../templates/PageText';
import { dateHelper } from '../../../helpers/dateHelper';
import Restricted from '../../Restricted';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonGroup } from '@mui/material';
import { permissions } from '../../../configs/permissionConstants';
import { useForm } from 'react-hook-form';


export default function SubcoolEvent() {

  const {
    activeEntity,
    setActiveEntity,
    upsertEntity,
  } = useContext(SubcoolEventContext);

  const [params, , goBack] = useNavigateTo();
  const subcool_event_uuid = params.subcool_event_uuid;
  const [items, setItems] = useState([]);

  const lookup = useContext(LookupContext);
  const { user } = useAuth0();

  function newItem(nickname, change, reason) {
    var theDate = new Date()
    return {
      data: theDate.toISOString(),
      id: theDate.toISOString(),
      title: dateHelper.formatLocalDateTimeDash(theDate).concat(' | ', nickname, ' | ', change, ':', reason)
    }
  }

  const form = useForm({
  });

  function onSubmit(data) {
    let update_subcool_usage = (data.update_subcool_usage) ? data.update_subcool_usage.toUpperCase() : ''
    let change = "Update subcool usage";
    return upsertEntity({ ...activeEntity, updated_subcool_usage: update_subcool_usage, manual_update_by: user.nickname, update_history: change.concat(":", update_subcool_usage) }).then(() => {
      setActiveEntity(subcool_event_uuid);
      items.unshift(newItem(user.nickname, change, update_subcool_usage))
    })
  }


  useEffect(() => {
    setActiveEntity(subcool_event_uuid);
    setItems(
      activeEntity?.update_history ?
        activeEntity.update_history.split('\n').map(item => ({
          ...item,
          data: item,
          id: item,
          title: item.replaceAll('|', ' | '),
        })) : []
    );
  }, []);

  return (
    <Page
      route={routeConfig.subcool_view}
      gap={1}
      onBack={goBack}
    >
      <PageSection
        title="Details"
        spacing={1}
        collapsible
        omitContainer
      >
        <PageText
          label="Site Name"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).label : ' - '}
        />
        <PageText
          label="Site UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid}
        />
        <PageText
          label="Event End Date"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.subcool_event_end ? dateHelper.formatLocalDateTime(activeEntity.subcool_event_end) : "-"}
        />
        <PageText
          label="Event UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.subcool_event_uuid}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Subcool Usage"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.subcool_usage}
        />
        <PageText
          label="Duration Minutes"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.subcool_duration_minutes}
        />
        <PageText
          label="Target Pressure"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.target_pressure}
        />
        <PageText
          label="Pressure Reached"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.pressure_reached}
        />
        <PageText
          label="Subcool Completed"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.subcool_did_not_complete ? "False" : "True"}
        />
        <PageText
          label="Subcool Count"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.subcool_count}
        />
        <PageText
          label="Updated Subcool Usage"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.updated_subcool_usage}
        />

      </PageSection>

      <Restricted to={[permissions.WRITE_SUBCOOLS]}>
        <PageSection
          title=''
          label='Update Subcool Usage'
          startActions={
            <MForm
              onSubmit={onSubmit}
              form={form}
              omitSubmitButton
            >
              <MFormTextField
                name="update_subcool_usage"
                label='Update Usage'
                GridProps={{ xs: 12, md: 12 }}
                onControlEnter={form.handleSubmit(onSubmit)}
                control={form.control}
              />
            </MForm>
          }
          endActions={<ButtonGroup sx={{ mr: 2 }}>
            <MButton
              onClick={form.handleSubmit(onSubmit)}
              color="primary"
              children="Update Usage"
              tooltip={"Update Subcool Usage"}
            />
          </ButtonGroup>}
        >
        </PageSection>
      </Restricted>
      <PageSection
        title="History"
        spacing={1}
        collapsible
        omitContainer
      >
        <MList
          dense
          items={items}
        />
      </PageSection>

    </Page >
  );
}