import React from 'react';
import './App.css';
import MainRoutes from './Routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './components/LoginButton';
import Logo from './components/Logo';
import {
  LayoutContextProvider,
  SupplierContextProvider,
  ErrorContextProvider,
  LookupContextProvider,
  ParentCompanyContextProvider,
  RegionalCompanyContextProvider,
  BusinessUnitContextProvider,
  SiteContextProvider,
  TankDeliveryEventContextProvider,
  UsageEventContextProvider,
  TakeOrPayTermContextProvider,
  PricingRateContextProvider,
  PricingFeeContextProvider,
  PricingMinimumContextProvider,
  CustomerContractContextProvider,
  SupplierContractContextProvider,
  SupplierContractRateContextProvider,
  DeliveryTicketContextProvider,
  RepresentativeContextProvider,
} from './contexts';
import { useWindowDimensions } from './helpers/useWindowDimensions';
import { SnackbarProvider } from 'notistack';
import { LoadingSpinner } from './components/containers';
import { PermissionProvider } from './contexts/PermissionContext';
import ConfirmDialog from './components/ConfirmDialog';
import { SubcoolEventContextProvider } from './contexts/SubcoolEventContext';

function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();
  const windowDimensions = useWindowDimensions();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <PermissionProvider>
        {!isAuthenticated ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <Logo /><p />
            <LoginButton />
          </div>

        ) :
          <LookupContextProvider>
            <ThemeConfig>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: windowDimensions.isMobile ? 'top' : 'bottom',
                  horizontal: 'left',
                }}
              >
                <ErrorContextProvider>
                  <LayoutContextProvider windowDimensions={windowDimensions}>
                    <DeliveryTicketContextProvider>
                      <TankDeliveryEventContextProvider>
                        <ParentCompanyContextProvider>
                          <RegionalCompanyContextProvider>
                            <BusinessUnitContextProvider>
                              <SiteContextProvider>
                                <SupplierContextProvider>
                                  <UsageEventContextProvider>
                                    <RepresentativeContextProvider>
                                      <TakeOrPayTermContextProvider>
                                        <PricingRateContextProvider>
                                          <CustomerContractContextProvider >
                                            <PricingFeeContextProvider >
                                              <PricingMinimumContextProvider >
                                                <SupplierContractContextProvider>
                                                  <SupplierContractRateContextProvider>
                                                    <TakeOrPayTermContextProvider>
                                                      <SubcoolEventContextProvider>
                                                      <GlobalStyles />
                                                      <ConfirmDialog />
                                                      <MainRoutes />
                                                      </SubcoolEventContextProvider>
                                                    </TakeOrPayTermContextProvider>
                                                  </SupplierContractRateContextProvider>
                                                </SupplierContractContextProvider>
                                              </PricingMinimumContextProvider>
                                            </PricingFeeContextProvider>
                                          </CustomerContractContextProvider>
                                        </PricingRateContextProvider>
                                      </TakeOrPayTermContextProvider>
                                    </RepresentativeContextProvider>
                                  </UsageEventContextProvider>
                                </SupplierContextProvider>
                              </SiteContextProvider>
                            </BusinessUnitContextProvider>
                          </RegionalCompanyContextProvider>
                        </ParentCompanyContextProvider>
                      </TankDeliveryEventContextProvider>
                    </DeliveryTicketContextProvider>
                  </LayoutContextProvider>
                </ErrorContextProvider>
              </SnackbarProvider>
            </ThemeConfig>
          </LookupContextProvider>
        }
      </PermissionProvider>
    </div >
  );
}

export default App;
