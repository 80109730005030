import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

const defaultBehaviour = {
  isAllowedTo: (permissionList) => false
}

const PermissionContext = React.createContext(defaultBehaviour);

export const PermissionProvider = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const token = await getAccessTokenSilently();
      setPermissions(jwtDecode(token)["permissions"]);
      // console.log("CURRENT PERMISSIONS: " + JSON.stringify(jwtDecode(token)) );
      console.log("ACCESS TOKEN: " + token );

    };

    const fetchIdToken = async () => {
      // const res = await getIdTokenClaims();
      // console.log("ID TOKEN: " + JSON.stringify(jwtDecode(res.__raw)))
    }

    if (!isLoading && isAuthenticated) {
      fetchPermissions();
      fetchIdToken();
    }
    else {
      setPermissions([])
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently,getIdTokenClaims]);

  const isAllowedTo = (permissionList) => {
    for (let permission of permissionList) {
      if (permissions.includes(permission)) {
        return true;
      }
    }
    return false;
  }

  return (
    <PermissionContext.Provider value={{ isAllowedTo }} >{children}</PermissionContext.Provider>
  )
}

export const usePermission = (permissionList) => {
  const { isAllowedTo } = useContext(PermissionContext);
  return [isAllowedTo(permissionList)];
}

export default PermissionContext;