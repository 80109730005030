export const dateHelper = {

  getToday: () => {
    return new Date();
  },

  getStartOfYear: (d = new Date()) => {
    return new Date(d.getFullYear(), 0, 1);
  },

  getBackMonths: (d = new Date(), months = 6) => {
    d.setMonth(d.getMonth() - months);
    return d;
  },

  getBackDays: (d = new Date(), days = 5) => {
    let ms_in_days = 86400000
    return new Date(d - (days * ms_in_days));
  },

  getStartDateFromUrlOrDefault: (searchParams) => {
    const dateFromParams = searchParams.get("start_date")
    if (dateFromParams)
      return new Date(dateFromParams)
    return dateHelper.getBackDays(new Date(), 14)
  },

  getEndDateFromUrlOrDefault: (searchParams) => {
    const dateFromParams = searchParams.get("end_date")
    if (dateFromParams)
      return new Date(dateFromParams)
    return dateHelper.getBackDays(new Date(), -1)
  },

  /**
   * Returns a date representation of the time string.
   * @param {*} timeString 
   * @returns 
   */
  setDateTime: (timeString) => {
    if (!timeString) {
      return;
    }
    let times = timeString.split(":");

    let hours = Number(times[0]);
    let minutes = Number(times[1]);
    const date = new Date();

    date.setHours(hours, minutes);
    return date;
  },

  /**
   * Maps date to API-accepted date string.
   * @param {*} datetime 
   * @returns 
   */
  getDateTime: (datetime) => {
    if (!datetime) {
      return;
    }
    let hh = "00";
    let mm = "00";
    let ss = "00";
    if (typeof datetime === "object") {
      hh = datetime.getHours();
      mm = datetime.getMinutes();
      ss = datetime.getSeconds();
    } else if (typeof datetime === "string") {
      let d = new Date(datetime);
      hh = d.getHours();
      mm = d.getMinutes();
      ss = d.getSeconds();
    };
    if (hh < 10) hh = '0' + hh;
    if (mm < 10) mm = '0' + mm;
    if (ss < 10) ss = '0' + ss;
    return `${hh}:${mm}:${ss}`;
  },

  formatTime: (timeString) => {
    let times = timeString.split(":");

    let hours = Number(times[0]);
    let minutes = Number(times[1]);

    const ampm = hours >= 12 ? 'pm' : 'am';

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  },


  formatDate: (date) => {
    if (!date) {
      return dateHelper.formatDate(new Date());
    }
    let yyyy;
    let mm;
    let dd;
    if (typeof date === "string") {
      const [d1, d2, d3] = date.split("-");
      yyyy = Number(d1);
      mm = Number(d2);
      dd = Number(d3);
    } else {
      yyyy = date.getFullYear();
      mm = date.getMonth() + 1; // Months start at 0!
      dd = date.getDate();
    }
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return `${yyyy}-${mm}-${dd}`;
  },

  formatDateTime: (dateTime) => {
    let parts = dateTime.split("T");
    let date = parts[0];
    let time = parts[1];

    return `${dateHelper.formatDate(date)} ${dateHelper.formatTime(time)}`;
  },

  formatDateShort: (dateTime) => {
    var date = new Date(Date.parse(dateTime)).toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return date;
  },

  formatDateTimeShort: (dateTime) => {
    var locale_date = new Date(Date.parse(dateTime)).toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
    });

    locale_date = locale_date.replace(',', '').replace('AM', 'am').replace('PM', 'pm');
    return locale_date;
  },

  formatLocalDateTime: (dateTime) => {
    var locale_date = new Date(Date.parse(dateTime + '+00:00')).toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    locale_date = locale_date.replace(',', '').replace('AM', 'am').replace('PM', 'pm');
    return locale_date;
  },

  formatLocalDateTimeShort: (dateTime) => {
    var locale_date = new Date(Date.parse(dateTime + '+00:00')).toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
    });

    locale_date = locale_date.replace(',', '').replace('AM', 'am').replace('PM', 'pm');
    return locale_date;
  },
  
  formatLocalDate: (dateTime) => {
    var locale_date = new Date(dateTime).toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return locale_date;
  }, 

  formatLocalDateTimeMed: (dateTime) => {
    var locale_date = new Date(Date.parse(dateTime + '+00:00')).toLocaleDateString(undefined, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    });

    locale_date = locale_date.replace(',', '').replace('AM', 'am').replace('PM', 'pm');
    return locale_date;
  },

  formatLocalDateTimeDash: (dateTime) => {
    var year = dateTime.toLocaleString("default", { year: "numeric" });
    var month = dateTime.toLocaleString("default", { month: "2-digit" });
    var day = dateTime.toLocaleString("default", { day: "2-digit" });
    var hour = dateTime.toLocaleString("default", { hour: "numeric", timeZone: 'America/Denver' });
    var minute = dateTime.toLocaleString("default", { minute: "numeric" });
    var second = dateTime.toLocaleString("default", { second: "numeric" });
    return year.concat('-', month, '-', day, ' ', hour.replace(/ .*/, ''), ':', minute, ':', second)
  }

}