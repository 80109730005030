import Page from "../../../templates/Page";
import { PageSection } from "../../../templates/PageSection";
import { dateHelper, useNavigateTo } from '../../../../helpers';
import { routeConfig } from '../../../../configs';
import { DeliveryTicketContext, LayoutContext, LookupContext, TankDeliveryEventContext } from "../../../../contexts";
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";
import { LoadingSpinner } from "../../../containers";
import { Search } from "@material-ui/icons";
import { MAutoComplete, MButton, MDatePicker, MFormGroup } from "../../../@material-extend";
import RefreshIcon from '@mui/icons-material/Refresh';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box sx={{ width: 20, }} />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Delivery Log',
          fields: ['site_uuid', 
            'jira_ticket_number', 
            'customer_status', 
            'confirmed_delivery_window_start',
            'confirmed_delivery_window_end',
            'delivery_end_date_seeq', 
            'pod_quantity',
            'delivery_ticket_created_at'
          ],
          utf8WithBom: true,
        }}
        printOptions={{
          disableToolbarButton: true
        }}
      />
      <Box sx={{ width: 100, }} />
    </GridToolbarContainer>
  );
}

export default function CustomerDeliveryTicketTable() {

  /* ---------------------------- Navigation Hooks ---------------------------- */
  const [searchParams, setSearchParams] = useSearchParams();
  const [, navigateTo] = useNavigateTo();

  /* ----------------------------- Context Providers ----------------------------- */
  const lookup = useContext(LookupContext);
  const {
    windowDimensions: { height },
    constants,
    setPageState,
    pageState,
    isLoading,
    handleError,
  } = useContext(LayoutContext);

  const tankDeliveryEventContext = useContext(TankDeliveryEventContext)
  const deliveryTicketContext = useContext(DeliveryTicketContext)

  /* --------------------------- React State Values --------------------------- */
  const [tickets, setTickets] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [sortModel, setSortModel] = React.useState([{
    field: 'delivery_ticket_created_at',
    sort: 'desc',
  }]);
  const [startDateProps, setStartDateProps] = useState({
    minDate: new Date(2025, 0, 1),
    maxDate: dateHelper.getToday(),
  });
  const [endDateProps, setEndDateProps] = useState({
    minDate: deliveryTicketContext.queryHandler.values.start_date || dateHelper.getToday(),
    maxDate: dateHelper.getBackDays(dateHelper.getToday(), -1),
  });
  const [isDataLoading, setIsDataLoading] = useState(false);

  /* -------------------------- Memoized State Values ------------------------- */

  /** Memoized options for the site dropdown */
  const options = React.useMemo(() => {
    if (!lookup?.values?.site_hierarchy.length) return []
    return lookup.values.site_hierarchy.map(site => ({ label: site.label, id: site.site_uuid }))
  }, [lookup.values.site_hierarchy])

  /**
   * Site Name Comparator
   * useCallback to prevent this from getting redefined on every rerender
   */
    const siteNameComparator = React.useCallback((id1, id2) =>
      lookup.byValue('site_hierarchy', id1).label.localeCompare(lookup.byValue('site_hierarchy', id2).label), [lookup]);

  /** Memoized columns */
  const columns = React.useMemo(() => [
    {
      field: 'site_uuid',
      description: 'Site',
      headerName: 'Site',
      hide: false,
      widthMultiplier: 5,
      sortComparator: siteNameComparator,
      renderCell: (params) => {
        var site_name = lookup.byValue('site_hierarchy', params.value).label
        if (typeof site_name === "undefined") {
          site_name = '-'
        }
        return (
          site_name
        )
      },
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return `${lookup.byValue('site_hierarchy', value).label}`;
      },
    },
    {
      field: 'jira_ticket_number',
      description: 'Delivery Id',
      headerName: 'Delivery Id',
      widthMultiplier: 2.5,
    },
    {
      field: 'customer_status',
      description: 'Status',
      headerName: 'Status',
      widthMultiplier: 2,
    },
    {
      field: 'confirmed_delivery_window_start',
      description: 'Confirmed Delivery Window Start',
      headerName: 'Window Start',
      widthMultiplier: 3.0,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatDateTimeShort(params.value) : "-"
        )
      },
    },
    {
      field: 'confirmed_delivery_window_end',
      description: 'Confirmed Delivery Window End',
      headerName: 'Window End',
      widthMultiplier: 3.0,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatDateTimeShort(params.value) : "-"
        )
      },
    },
    {
      field: 'delivery_end_date_seeq',
      description: 'Delivered Date',
      headerName: 'Delivered Date',
      widthMultiplier: 3.0,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatDate(params.value) : "-"
        )
      },
    },
    {
      field: 'pod_quantity',
      description: 'POD Quantity',
      headerName: 'POD Qty',
      widthMultiplier: 2,
      renderCell: (params) => {
        return (
          (params?.value) ? params.value : "-"
        )
      },
    },
    {
      field: 'delivery_ticket_created_at',
      description: 'Delivery Ticket Created',
      headerName: 'Created',
      widthMultiplier: 3.0,
      renderCell: (params) => {
        return (
          (params?.value) ? dateHelper.formatDateShort(params.value) : "-"
        )
      },
    }
  ].map(fullCol => {
    const { widthMultiplier, ...col } = fullCol;
    return {
      ...col,
      width: 50 * widthMultiplier,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }), [lookup, siteNameComparator]);

  /* ----------------------- Use Effect Lifecycle Hooks ----------------------- */

  /** Fetch data on initial load */
  React.useEffect(() => {

    deliveryTicketContext.queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    deliveryTicketContext.queryHandler.values.start_date = dateHelper.getStartOfYear()
    deliveryTicketContext.queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)

    tankDeliveryEventContext.queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    tankDeliveryEventContext.queryHandler.values.start_date = dateHelper.getStartOfYear()
    tankDeliveryEventContext.queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)

    queryData()

    setPageState({ ...pageState, dense: true })
    return () => {
      setPageState({
        ...pageState,
        dense: false
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Update the date range based on the query params and update URL params */
  React.useEffect(() => {
    setStartDateProps(prev => ({
      ...prev,
      maxDate: deliveryTicketContext.queryHandler.values.end_date || dateHelper.getToday(),
    }))
    setEndDateProps(prev => ({
      ...prev,
      minDate: deliveryTicketContext.queryHandler.values.start_date || dateHelper.getToday(),
    }))
    setSearchParams({
      ...searchParams,
      site_uuid: deliveryTicketContext.queryHandler.values?.site_uuid || "",
      start_date: deliveryTicketContext.queryHandler.values.start_date || dateHelper.getToday(),
      end_date: deliveryTicketContext.queryHandler.values.end_date || dateHelper.getToday()
    })
  }, [deliveryTicketContext.queryHandler.values.start_date, deliveryTicketContext.queryHandler.values.end_date, deliveryTicketContext.queryHandler.values.site_uuid, searchParams, setSearchParams])


  /* ----------------------- Local Functions ----------------------- */

  function mapCustomerStatus(delivery_ticket_status, issue_resolution) {
    if (issue_resolution == null) {
      return delivery_ticket_status
    }
    else if (delivery_ticket_status === 'Closed' && issue_resolution === 'Done') {
      return 'Billed'
    }
    else if (delivery_ticket_status === 'Closed' && issue_resolution === 'Delivered Do Not Bill') {
      return 'Delivered Do Not Bill'
    }
    else {
      return delivery_ticket_status + ' - ' + issue_resolution;
    }
  }

  const refreshData = () => {
    // TODO: can this be done with useLocation() ??
    setTimeout(() => {
      navigateTo(routeConfig.customer_delivery_ticket_table_refresh)
    });
    setTimeout(() => {
      navigateTo(routeConfig.customer_delivery_ticket_table)
    });
  }

  const queryData = () => {
    setIsDataLoading(true);
    const fetchDeliveryTicketData = deliveryTicketContext.queryHandler.fetch()
      .then(result => {
        return result;
      })
      .catch(error => {
        const errorMessage = "Unable to get Delivery Tickets: " + error
        handleError(errorMessage);
      });

    const fetchTankDeliveryEventData = tankDeliveryEventContext.queryHandler.fetch()
      .then(result => {
        return result;
      })
      .catch(error => {
        const errorMessage = "Unable to get Tank Delivery Events: " + error
        handleError(errorMessage);
      });

    Promise.all([fetchDeliveryTicketData, fetchTankDeliveryEventData])
      .then(([tickets, fills]) => {
        const mergedData = tickets['delivery_ticket'].map(item1 => {
          const item2 = fills['tank_delivery_event'].find(item => item.jira_ticket_number === item1.jira_ticket_number);
          return item2 ? { ...item1, ...item2 } : item1;
        });

        let filtered = mergedData.filter(ticket => ticket.issue_resolution !== 'Cancelled')
          .filter(ticket => ticket.delivery_ticket_status !== 'New');

        filtered = filtered.map(item => {
          return {
            ...item,
            customer_status: mapCustomerStatus(item.delivery_ticket_status, item.issue_resolution)
          }
        });

        setTickets(filtered)
        setIsDataLoading(false);
      });
  }

  return (
    <Page
      hideHeader
      fullWidth>
      <PageSection
        title={"Site Delivery Log"}
        direction="row"
        headerSx={{ mt: 1 }}
        onBack={() => navigateTo(routeConfig.dashboard)}
        onBackTooltip={"Back to Dashboard"}
      >
        <Grid container
          item
          spacing={5}
          sx={{ pr: 1 }}
          xs={12}
          md={6}
          lg={8}
        >
          {/* ------------------------------ Date Filter ------------------------------ */}
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Date Range"}
          >
            <MDatePicker
              {...startDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={deliveryTicketContext.queryHandler.values.start_date}
              label={"Start Date"}
              labelId={`select-start-date`}
              onChange={deliveryTicketContext.queryHandler.createValueChangeHandler("start_date")}
            />
            <MDatePicker
              {...endDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={deliveryTicketContext.queryHandler.values["end_date"]}
              placeholder="End Date"
              label={"End Date"}
              labelId={`select-start-date`}
              onChange={deliveryTicketContext.queryHandler.createValueChangeHandler("end_date")}
            />
          </MFormGroup>

          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Site"}
          >
            <MAutoComplete
              GridProps={{
                xs: 12,
                lg: 12,
              }}
              options={options}
              value={deliveryTicketContext.queryHandler.values["site_uuid"]}
              onChange={deliveryTicketContext.queryHandler.createValueChangeHandler("site_uuid")}
              includeAll
              name="site_uuid"
            />
          </MFormGroup>

        </Grid>
        {/* ------------------------------ Action Items ------------------------------ */}
        <MFormGroup
          GridProps={{ xs: 12, md: 6, lg: 4, spacing: 1, }}
          labelSx={{ mb: 1 }}
          title={`Actions`}
        >
          <MButton
            GridProps={{ xs: 6, md: 8, }}
            sx={{ mt: 1, }}
            variant="contained"
            size={"large"}
            color="primary"
            onClick={() => queryData()}
            children="Apply Filter"
            startIcon={<Search />}
            disabled={!deliveryTicketContext.queryHandler.isDirty}
          />
          <MButton
            GridProps={{
              xs: 6,
              md: 4,
            }}
            sx={{ mt: 0.6, }}
            size={"large"}
            color="primary"
            onClick={() => refreshData()}
            children="Reset"
            startIcon={<RefreshIcon />}
            tooltip="Reset the filter"
          />
        </MFormGroup>
      </PageSection>
      <div style={{
        display: 'flex',
        width: "100%",
        overflowX: "auto",
        height: height - constants.APP_BAR_HEIGHT
      }}>
        <div style={{ flexGrow: 1 }}>
          {isDataLoading ? <LoadingSpinner /> :
            <DataGrid
              loading={!!isLoading}
              rows={tickets}
              columns={columns}
              columnBuffer={25}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onPageSizeChange={setPageSize}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              getRowId={(row) => row.jira_ticket_number}
              sx={{
                marginLeft: '20px',  // Add margin-left directly via sx
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          }
        </div>
      </div>
    </Page>
  );
}