import Page from "../../../templates/Page";
import { PageSection } from "../../../templates/PageSection";
import { dateHelper, useNavigateTo } from '../../../../helpers';
import { routeConfig } from '../../../../configs';
import { LayoutContext, LookupContext, UsageEventContext } from "../../../../contexts";
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";
import { MAutoComplete, MButton, MDatePicker, MFormGroup } from "../../../@material-extend";
import { Search } from "@material-ui/icons";
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingSpinner } from "../../../containers";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box sx={{ width: 20, }} />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Usage Log',
          fields: ['site_uuid', 'date', 'usage_pulses'
          ],
          utf8WithBom: true,
        }}
        printOptions={{
          disableToolbarButton: true
        }}
      />
      <Box sx={{ width: 100, }} />
    </GridToolbarContainer>
  );
}

export default function CustomerUsageTable() {

  /* ---------------------------- Navigation Hooks ---------------------------- */
  const [searchParams, setSearchParams] = useSearchParams();
  const [, navigateTo] = useNavigateTo();

  /* ----------------------------- Context Providers ----------------------------- */
  const lookup = useContext(LookupContext);
  const {
    windowDimensions: { height },
    constants,
    setPageState,
    pageState,
    isLoading,
    handleError,
  } = useContext(LayoutContext);
  const {
    queryHandler
  } = useContext(UsageEventContext);

  /* --------------------------- React State Values --------------------------- */

  const [usages, setUsages] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [sortModel, setSortModel] = React.useState([{
    field: 'date',
    sort: 'desc',
  }]);
  const [startDateProps, setStartDateProps] = useState({
    minDate: undefined,
    maxDate: dateHelper.getToday(),
  });
  const [endDateProps, setEndDateProps] = useState({
    minDate: queryHandler.values.start_date || dateHelper.getToday(),
    maxDate: dateHelper.getBackDays(dateHelper.getToday(), -1),
  });
  const [isDataLoading, setIsDataLoading] = useState(false);

  /* -------------------------- Memoized State Values ------------------------- */

  /** Memoized options for the site dropdown */
  const options = React.useMemo(() => {
    if (!lookup?.values?.site_hierarchy.length) return []
    return lookup.values.site_hierarchy.map(site => ({ label: site.label, id: site.site_uuid }))
  }, [lookup.values.site_hierarchy])

  /**
   * Site Name Comparator
   * useCallback to prevent this from getting redefined on every rerender
   */
  const siteNameComparator = React.useCallback((id1, id2) =>
    lookup.byValue('site_hierarchy', id1).label.localeCompare(lookup.byValue('site_hierarchy', id2).label), [lookup]);

  /**
   * Memoized columns 
   */
  const columns = React.useMemo(() => [
    {
      field: 'site_uuid',
      description: 'Site',
      headerName: 'Site',
      hide: false,
      widthMultiplier: 5,
      sortComparator: siteNameComparator,
      renderCell: (params) => {
        var site_name = lookup.byValue('site_hierarchy', params.value).label
        if (typeof site_name === "undefined") {
          site_name = 'Retired'
        }
        return (
          site_name
        )
      },
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return `${lookup.byValue('site_hierarchy', value).label}`;
      },
    },
    {
      field: 'date',
      description: 'Usage End Date',
      headerName: 'Usage Date',
      widthMultiplier: 4,
      renderCell: (params) => {
        return (
          params.value
        )
      },
    },
    {
      field: 'usage_pulses',
      headerName: 'Pulses',
      widthMultiplier: 2.0,
    },
  ].map(fullCol => {
    const { widthMultiplier, ...col } = fullCol;
    return {
      ...col,
      width: (fullCol.widthMultiplier || 1) * 50,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [lookup.values.site_hierarchy, siteNameComparator])

  React.useEffect(() => {
    queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    queryHandler.values.start_date = dateHelper.getStartDateFromUrlOrDefault(searchParams)
    queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)
    queryHandler.fetch().then(res => {
      setSortModel([{ field: 'date', sort: 'desc' }]);
    }, setSortModel);
    setPageState({ ...pageState, dense: true })
    return () => {
      setPageState({
        ...pageState,
        dense: false
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* ----------------------- Use Effect Lifecycle Hooks ----------------------- */

  /** Fetch data on initial load */
  React.useEffect(() => {
    queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    queryHandler.values.start_date = dateHelper.getStartOfYear()
    queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)
    queryData()

    setSortModel([{ field: 'date', sort: 'desc' }]);
    setPageState({ ...pageState, dense: true })
    return () => {
      setPageState({
        ...pageState,
        dense: false
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /** Update the date range based on the query params and update URL params */
  React.useEffect(() => {
    setStartDateProps(prev => ({
      ...prev,
      minDate: new Date(2025, 0, 1),
      maxDate: queryHandler.values.end_date || dateHelper.getToday(),
    }))
    setEndDateProps(prev => ({
      ...prev,
      minDate: queryHandler.values.start_date || dateHelper.getToday(),
    }))
    setSearchParams({
      ...searchParams,
      site_uuid: queryHandler.values?.site_uuid || "",
      start_date: queryHandler.values.start_date || dateHelper.getToday(),
      end_date: queryHandler.values.end_date || dateHelper.getToday()
    })
  }, [queryHandler.values.start_date, queryHandler.values.end_date, queryHandler.values.site_uuid, searchParams, setSearchParams])

  /* ----------------------- Local Functions ----------------------- */

  const refreshData = () => {
    // TODO: can this be done with useLocation() ??
    setTimeout(() => {
      navigateTo(routeConfig.customer_usage_table_refresh)
    });
    setTimeout(() => {
      navigateTo(routeConfig.customer_usage_table)
    });
  }

  const queryData = () => {
    setIsDataLoading(true);

    const fetchUsageData = queryHandler.fetch()
      .then(result => {
        setIsDataLoading(false);
        return result;
      })
      .catch(error => {
        const errorMessage = "Unable to get customer usages: " + error
        handleError(errorMessage);
      });

    Promise.all([fetchUsageData])
      .then(([usageData]) => {
        let theUsages = usageData['usage_event'].filter(usage => usage.testing_mode !== true);
        if (theUsages.length > 0) {

          // roll up daily usage pulses
          theUsages = rollup(theUsages)
          setUsages(theUsages)
        }
        else {
          setUsages([])
        }
      });
  }

  const rollup = (data) => {
    const dailyTotals = {}
    for (const item of data) {
      const date = new Date(item.usage_begin_timestamp);
      const dateString = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
      const site_uuid = item.site_uuid
      const usage_pulses = item.usage_pulses

      const key = `${site_uuid}|${dateString}`
      if (!dailyTotals[key]) {
        dailyTotals[key] = {
          key: key,
          site_uuid: site_uuid,
          date: dateString,
          usage_pulses: 0
        };
      }
      dailyTotals[key].usage_pulses = dailyTotals[key].usage_pulses + usage_pulses
    }
    return Object.values(dailyTotals)
  }

  return (
    <Page
      hideHeader
      fullWidth>
      <PageSection
        title={"Site Usage Log"}
        direction="row"
        headerSx={{ mt: 1 }}
        onBack={() => navigateTo(routeConfig.dashboard)}
        onBackTooltip={"Back to Dashboard"}
      >
        <Grid container
          item
          spacing={5}
          sx={{ pr: 1 }}
          xs={12}
          md={6}
          lg={8}
        >
          {/* ------------------------------ Date Filter ------------------------------ */}
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Date Range"}
          >
            <MDatePicker
              {...startDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values.start_date}
              label={"Start Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("start_date")}
            />
            <MDatePicker
              {...endDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values["end_date"]}
              placeholder="End Date"
              label={"End Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("end_date")}
            />
          </MFormGroup>

          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Site"}
          >
            <MAutoComplete
              GridProps={{
                xs: 12,
                lg: 12,
              }}
              options={options}
              value={queryHandler.values["site_uuid"]}
              onChange={queryHandler.createValueChangeHandler("site_uuid")}
              includeAll
              name="site_uuid"
            />
          </MFormGroup>

        </Grid>

        {/* ------------------------------ Action Items ------------------------------ */}
        <MFormGroup
          GridProps={{ xs: 12, md: 6, lg: 4, spacing: 1, }}
          labelSx={{ mb: 1 }}
          title={`Actions`}
        >

          <MButton
            GridProps={{ xs: 6, md: 8, }}
            sx={{ mt: 1, }}
            variant="contained"
            size={"large"}
            color="primary"
            onClick={() => queryData()}
            children="Apply Filter"
            startIcon={<Search />}
            disabled={!queryHandler.isDirty}
          />
          <MButton
            GridProps={{
              xs: 6,
              md: 4,
            }}
            sx={{ mt: 0.6, }}
            size={"large"}
            color="primary"
            onClick={() => refreshData()}
            children="Reset"
            startIcon={<RefreshIcon />}
            tooltip="Reset the filters"
          />
        </MFormGroup>
      </PageSection>
      <div style={{
        display: 'flex',
        width: "100%",
        overflowX: "auto",
        height: height - constants.APP_BAR_HEIGHT
      }}>
        <div style={{ flexGrow: 1 }}>
          {isDataLoading ? <LoadingSpinner /> :
            <DataGrid
              loading={!!isLoading}
              rows={usages}
              columns={columns}
              columnBuffer={25}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onPageSizeChange={setPageSize}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              getRowId={(row) => row.key}
              sx={{
                marginLeft: '20px',  // Add margin-left directly via sx
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          }
        </div>
      </div>
    </Page>
  );
}