/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { entityConfig, lookupConfig } from "../configs";
import { useLookup } from "../helpers";
import { defaultSite, defaultSupplier, defaultParentCompany, defaultRegionalCompany, defaultBusinessUnit } from "../services";

const Sort = {
    byLabel: (a, b) => {
        if (a.label < b.label) {
            return -1;
        } else if (a.label > b.label) {
            return 1;
        } else {
            return 0;
        }
    },
}


/**
 * @template {{value: string, label: string}} LookupValue
 * @type {{
 *      getSiteView: (site_uuid: string | (site) => boolean) => 
 *          typeof defaultSite 
 *          & typeof defaultParentCompany 
 *          & typeof defaultRegionalCompany 
 *          & typeof defaultBusinessUnit &{
 *              default_supplier: typeof defaultSupplier,
 *          },
 *      config: typeof lookupConfig,
 *      isLoading: boolean;
 *      byTypeUuid: <V = {}>(lookupType: keyof lookupConfig, value, defaultValue: V) => LookupValue & V,
 *      byTypeKey: <V>(lookupType: keyof lookupConfig,  value: V, key: keyof V) => LookupValue,
 *      byValue: <V>(lookupType: keyof lookupConfig, lookupValue) => LookupValue,
 *      refreshLookup: (lookupType: keyof lookupConfig) => void,
 *      values: {
 *          application: LookupValue[];
 *          business_unit:  {
 *              business_unit_name: string;
 *              business_unit_uuid: string;
 *              regional_company_uuid: string;
 *          }[];
 *          channel: LookupValue[];
 *          continent: LookupValue[];
 *          country: LookupValue[];
 *          customer_contract: LookupValue[];
 *          customer_contract_status: LookupValue[];
 *          currency: LookupValue[];
 *          language: LookupValue[];
 *          msa_country: LookupValue[];
 *          minimum_type: LookupValue[]; 
 *          nitrocrete_region: LookupValue[];
 *          nitrocrete_representative: LookupValue[];
 *          parent_company: {
 *              parent_company_name: string;
 *              parent_company_uuid: string;
 *          }[];  
 *          piping_type: LookupValue[];
 *          po_number_type: LookupValue[];
 *          plant_type: LookupValue[];
 *          fee_type: LookupValue[];
 *          pricing_model: LookupValue[];
 *          pricing_rate: LookupValue[];
 *          representative_assignment: LookupValue[];
 *          regional_company: {
 *              parent_company_uuid: string;
 *              regional_company_name: string;
 *              regional_company_uuid: string;
 *          }[];
 *          site: {
 *               access_instructions:  string;
 *               application:  string;
 *               business_unit_uuid: string;
 *               channel:  string;
 *               country_code:  string;
 *               currency_code:  string;
 *               delivery_restricted:  boolean;
 *               erp_site_id:  string;
 *               language_used:  string;
 *               nat_box_count:  string;
 *               nitro_deliver:  false;
 *               number_of_lanes:  string;
 *               piping_type:  string;
 *               plant_type:  string;
 *               site_address1: string;
 *               site_address2:  string;
 *               site_city:  string;
 *               site_description:  string;
 *               site_name:  string;
 *               site_postal_code:  string;
 *               site_status:  string;
 *               site_uuid:  string;
 *               site_year:  string;
 *               state_code:  string;
 *               system_status:  string;
 *               tank_capacity_gallons:  string;
 *               tank_description:  string;
 *               tank_quantity:  string;
 *               tank_rental:  false;
 *               tank_status:  string;
 *               tank_supplier:  string;
 *               tank_type:  string;
 *               territory:  string;
 *               timezone_name:  string;
 *               unit_of_measure:  string; 
 *          }[];
 *          site_contact_type: LookupValue[];
 *          site_status: LookupValue[];
 *          state: (LookupValue & {country_code: string; state_code: string; state_name: string; subdivision_type: string;})[];
 *          supplier: (typeof defaultSupplier & LookupValue)[];
 *          supplier_contract_status: LookupValue[];
 *          system_status: LookupValue[];
 *          take_or_pay_term: LookupValue[];
 *          tank_status: LookupValue[];
 *          tank_supplier: LookupValue[];
 *          tank_type: LookupValue[];
 *          territory: LookupValue[];
 *          timezone: LookupValue[];
 *          unit_of_measure: LookupValue[];
 *      }
 * }} LookupContextState
 */
const defaultState = {
    getSiteView: (site_uuid) => ({
        ...defaultSite,
        ...defaultParentCompany,
        ...defaultRegionalCompany,
        ...defaultBusinessUnit,
    }),
    byTypeUuid: (lookupType, value, defaultValue) => { },
    byTypeKey: (lookupType, value, key) => { },
    byValue: (lookupType, lookupValue) => { },
    values: {
        application: [],
        business_unit: [],
        channel: [],
        continent: [],
        country: [],
        currency: [],
        customer_contract: [],
        customer_contract_status: [],
        language: [],
        minimum_type: [],
        nitrocrete_region: [],
        nitrocrete_representative: [],
        parent_company: [],
        piping_type: [],
        po_number_type: [],
        plant_type: [],
        fee_type: [],
        pricing_model: [],
        pricing_rate: [],
        regional_company: [],
        representative_assignment: [],
        representative_type: [],
        site: [],
        site_contact_type: [],
        site_hierarchy: [],
        site_status: [],
        state: [],
        supplier: [],
        supplier_contract: [],
        supplier_contract_status: [],
        supplier_contract_charge_type: [],
        supplier_contract_rate_type: [],
        system_status: [],
        take_or_pay_term: [],
        tank_status: [],
        tank_supplier: [],
        tank_type: [],
        territory: [],
        timezone: [],
        unit_of_measure: [],
    },
}

export const LookupContext = React.createContext(defaultState);

export function LookupContextProvider(props) {

    const [application, application_isLoading] = useLookup(lookupConfig.application);
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState(defaultState.values);

    const [business_unit, business_unit_isLoading] = useLookup(entityConfig.type.business_unit, {
        sort: Sort.byLabel,
        mapLookup: (b) => ({
            id: b.business_unit_uuid,
            business_unit_name: b.business_unit_name,
            business_unit_uuid: b.business_unit_uuid,
            label: b.business_unit_name,
            value: b.business_unit_uuid
        }),
    });

    const [channel, channel_isLoading] = useLookup(lookupConfig.channel);
    const [continent, continent_isLoading] = useLookup(lookupConfig.continent, {
        // type tz = {continent_code, continent_name}
        mapLookup: lu => ({
            ...lu,
            value: lu.continent_code,
            label: lu.continent_name
        })
    })
    const [country, country_isLoading] = useLookup(lookupConfig.country, {
        // type tz = {continent_code, country_code, country_name}
        mapLookup: lu => ({
            ...lu,
            value: lu.country_code,
            label: lu.country_name
        })
    });

    const [currency, currency_isLoading] = useLookup(lookupConfig.currency, {
        mapLookup: (lu) => ({
            label: lu.currency,
            value: lu.currency_code
        })
    });

    const [customer_contract, customer_contract_isLoading] = useLookup(lookupConfig.customer_contract);
    const [customer_contract_status, customer_contract_status_isLoading] = useLookup(lookupConfig.customer_contract_status, {
        mapLookup: (lu) => ({
            label: lu.customer_contract_status_description,
            value: lu.customer_contract_status
        }),
    });

    const [language, language_isLoading] = useLookup(lookupConfig.language, {
        mapLookup: (lu) => ({
            label: lu.language_description,
            value: lu.language_used
        }),
    });
    const [minimum_type, minimum_type_isLoading] = useLookup(lookupConfig.minimum_type);
    const [nitrocrete_region, nitrocrete_region_isLoading] = useLookup(lookupConfig.nitrocrete_region);
    const [nitrocrete_representative, nitrocrete_representative_isLoading] = useLookup(lookupConfig.nitrocrete_representative, {
        sort: Sort.byLabel,
        mapLookup: (nr) => ({
            id: nr.nitrocrete_representative,
            label: nr.nitrocrete_representative_description,
            value: nr.nitrocrete_representative,
            is_active: nr.nitrocrete_representative_active
        }),
    });

    const [parent_company, parent_company_isLoading] = useLookup(entityConfig.type.parent_company, {
        sort: Sort.byLabel,
        mapLookup: (p) => ({
            id: p.parent_company_uuid,
            label: p.parent_company_name,
            value: p.parent_company_uuid
        }),
    });

    const [piping_type, piping_type_isLoading] = useLookup(lookupConfig.piping_type);
    const [po_number_type, po_number_type_isLoading] = useLookup(lookupConfig.po_number_type)
    const [plant_type, plant_type_isLoading] = useLookup(lookupConfig.plant_type);
    const [fee_type, fee_type_isLoading] = useLookup(lookupConfig.fee_type);
    const [pricing_model, pricing_model_isLoading] = useLookup(lookupConfig.pricing_model);
    const [pricing_rate, pricing_rate_isLoading] = useLookup(lookupConfig.pricing_rate);

    const [regional_company, regional_company_isLoading] = useLookup(entityConfig.type.regional_company, {
        sort: Sort.byLabel,
        mapLookup: (r) => ({
            id: r.regional_company_uuid,
            regional_company_uuid: r.regional_company_uuid,
            regional_company_name: r.regional_company_name,
            parent_company_uuid: r.parent_company_uuid,
            label: r.regional_company_name,
            value: r.regional_company_uuid
        }),
    });

    const [representative_assignment, representative_assignment_isLoading] = useLookup(lookupConfig.representative_assignment);

    const [representative_type, representative_type_isLoading] = useLookup(lookupConfig.representative_type);

    const [site, site_isLoading] = useLookup(entityConfig.type.site, {
        sort: Sort.byLabel,
        mapLookup: (site) => ({
            id: site.site_uuid,
            label: site.site_name,
            value: site.site_uuid
        }),
    });

    const [site_contact_type, site_contact_type_isLoading] = useLookup(lookupConfig.site_contact_type);
    const [site_hierarchy, site_hierarchy_isLoading] = useLookup(lookupConfig.site_hierarchy, {
        sort: Sort.byLabel,
        mapLookup: (site_hierarchy) => ({
            id: site_hierarchy.site_uuid,
            label: site_hierarchy.name,
            uom: site_hierarchy.unit_of_measure,
            tank_type: site_hierarchy.tank_type_description,
            value: site_hierarchy.site_uuid
        }),
    });
    const [site_status, site_status_isLoading] = useLookup(lookupConfig.site_status);
    const [state_raw, state_raw_isLoading] = useLookup(lookupConfig.state, {
        // type state = {country_code, state_code, state_name, subdivision_type}
        mapLookup: lu => ({
            ...lu,
            country_code: lu.country_code,
            state_code: lu.state_code,
            state_name: lu.state_name,
            subdivision_type: lu.subdivision_type,
            value: lu.state_code,
            label: lu.state_name,
        })
    });
    const state = useMemo(() => {
        if (!state_raw.length || !country.length || !continent.length)
            return [];
        return state_raw.map(s => {
            const stateCountry = country.find(c => c.country_code === s.country_code);
            const stateContinent = continent.find(c => c.continent_code === stateCountry.continent_code);

            return {
                ...s,
                value: s.state_code,
                label: `${stateContinent.continent_name} - ${stateCountry.country_name} - ${s.state_name}`,
            }
        })
    }, [state_raw, country, continent])

    const [supplier, supplier_isLoading] = useLookup(entityConfig.type.supplier, {
        sort: Sort.byLabel,
        mapLookup: (supplier) => ({
            label: supplier.supplier_name,
            value: supplier.supplier_uuid
        }),
    });

    const [supplier_contract, supplier_contract_isLoading] = useLookup(lookupConfig.supplier_contract);

    const [supplier_contract_status, supplier_contract_status_isLoading] = useLookup(lookupConfig.supplier_contract_status);

    const [supplier_contract_charge_type, supplier_contract_charge_type_isLoading] = useLookup(lookupConfig.supplier_contract_charge_type);

    const [supplier_contract_rate_type, supplier_contract_rate_type_isLoading] = useLookup(lookupConfig.supplier_contract_rate_type);

    const [system_status, system_status_isLoading] = useLookup(lookupConfig.system_status);
    const [take_or_pay_term, take_or_pay_term_isLoading] = useLookup(lookupConfig.take_or_pay_term);
    const [tank_status, tank_status_isLoading] = useLookup(lookupConfig.tank_status);
    const [tank_supplier, tank_supplier_isLoading] = useLookup(lookupConfig.tank_supplier);
    const [tank_type, tank_type_isLoading] = useLookup(lookupConfig.tank_type);
    const [territory_raw, territory_raw_isLoading] = useLookup(lookupConfig.territory, {
        sort: Sort.byLabel,
        mapLookup: (lu) => ({
            nitrocrete_region: lu.nitrocrete_region,
            territory: lu.territory,
            territory_description: lu.territory_description,
        }),
    });
    const territory = useMemo(() => {
        if (!territory_raw.length || !nitrocrete_region.length)
            return [];
        const result = territory_raw.map(t => {
            const territoryRegion = nitrocrete_region.find(r => r.nitrocrete_region === t.nitrocrete_region);
            return {
                ...t,
                value: t.territory,
                label: `${territoryRegion.nitrocrete_region_description} - ${t.territory_description}`,
            }
        });
        return result.sort(Sort.byLabel);
    }, [territory_raw, nitrocrete_region]);

    const [timezone, timezone_isLoading] = useLookup(lookupConfig.timezone, {
        // type tz = {country_code, dst_offset, timezone_name, utc_offset}
        mapLookup: lu => ({
            ...lu,
            value: lu.timezone_name,
            label: lu.timezone_name
        })
    });
    const [unit_of_measure, unit_of_measure_isLoading] = useLookup(lookupConfig.unit_of_measure, {
        sort: Sort.byLabel,
        mapLookup: (b) => ({
            label: b.unit_of_measure,
            value: b.unit_of_measure,
        }),
    });


    useEffect(() => {
        const newIsLoading =
            application_isLoading ||
            business_unit_isLoading ||
            channel_isLoading ||
            continent_isLoading ||
            country_isLoading ||
            currency_isLoading ||
            customer_contract_status_isLoading ||
            customer_contract_isLoading ||
            language_isLoading ||
            minimum_type_isLoading ||
            nitrocrete_region_isLoading ||
            nitrocrete_representative_isLoading ||
            parent_company_isLoading ||
            piping_type_isLoading ||
            po_number_type_isLoading ||
            plant_type_isLoading ||
            fee_type_isLoading ||
            pricing_model_isLoading ||
            pricing_rate_isLoading ||
            regional_company_isLoading ||
            representative_assignment_isLoading ||
            representative_type_isLoading ||
            site_isLoading ||
            site_contact_type_isLoading ||
            site_hierarchy_isLoading ||
            site_status_isLoading ||
            state_raw_isLoading ||
            supplier_isLoading ||
            supplier_contract_isLoading ||
            supplier_contract_status_isLoading ||
            supplier_contract_charge_type_isLoading ||
            supplier_contract_rate_type_isLoading ||
            system_status_isLoading ||
            take_or_pay_term_isLoading ||
            tank_status_isLoading ||
            tank_supplier_isLoading ||
            tank_type_isLoading ||
            territory_raw_isLoading ||
            timezone_isLoading ||
            unit_of_measure_isLoading;

        if (newIsLoading !== isLoading) {
            setIsLoading(newIsLoading);
        }

        if (!newIsLoading) {


            const newValues = {
                application,
                business_unit,
                channel,
                continent,
                country,
                currency,
                customer_contract_status,
                customer_contract,
                language,
                minimum_type,
                nitrocrete_region,
                nitrocrete_representative,
                parent_company,
                piping_type,
                po_number_type,
                plant_type,
                fee_type,
                pricing_model,
                pricing_rate,
                regional_company,
                representative_assignment,
                representative_type,
                site,
                site_contact_type,
                site_hierarchy,
                site_status,
                state,
                supplier,
                supplier_contract,
                supplier_contract_status,
                supplier_contract_charge_type,
                supplier_contract_rate_type,
                system_status,
                take_or_pay_term,
                tank_status,
                tank_supplier,
                tank_type,
                territory,
                timezone,
                unit_of_measure,
            };

            if (newValues !== values) {
                setValues(newValues);
            }
        }
    }, [
        application_isLoading,
        business_unit_isLoading,
        channel_isLoading,
        continent_isLoading,
        country_isLoading,
        currency_isLoading,
        customer_contract_status_isLoading,
        customer_contract_isLoading,
        language_isLoading,
        minimum_type_isLoading,
        nitrocrete_region_isLoading,
        nitrocrete_representative_isLoading,
        parent_company_isLoading,
        piping_type_isLoading,
        po_number_type_isLoading,
        plant_type_isLoading,
        fee_type_isLoading,
        pricing_model_isLoading,
        pricing_rate_isLoading,
        regional_company_isLoading,
        representative_assignment_isLoading,
        representative_type_isLoading,
        site_isLoading,
        site_contact_type_isLoading,
        site_hierarchy_isLoading,
        site_status_isLoading,
        state_raw_isLoading,
        supplier_isLoading,
        supplier_contract_isLoading,
        supplier_contract_status_isLoading,
        supplier_contract_charge_type_isLoading,
        supplier_contract_rate_type_isLoading,
        system_status_isLoading,
        take_or_pay_term_isLoading,
        tank_status_isLoading,
        tank_supplier_isLoading,
        tank_type_isLoading,
        territory_raw_isLoading,
        timezone_isLoading,
        unit_of_measure_isLoading,

    ])

    const lookupByUuid = (lookupType, value, defaultValue) => {
        if (!value || !value[`${lookupType}_uuid`]) {
            return defaultValue || {};
        };
        return values[lookupType].find(lookup => lookup[`${lookupType}_uuid`] === value[`${lookupType}_uuid`]) || defaultValue || {};
    }
    const lookupByKey = (lookupType, value, valueKey, lookupKey) => {
        if (!value || !value[valueKey]) {
            return {};
        };
        return values[lookupType].find(lookup => lookup[lookupKey || valueKey] === value[valueKey]) || {};
    }

    const getSiteView = (site_uuid) => {
        const site = (typeof site_uuid === "string" ? values.site.find(site => site.site_uuid === site_uuid) : values.site.find(site_uuid)) || {};
        // TODO:  Use effective supplier contract for supplier?
        const default_supplier = values.supplier.find(supplier => supplier.supplier_uuid === site.site_uuid) || defaultSupplier;
        const business_unit = values.business_unit.find(business => business.business_unit_uuid === site.business_unit_uuid) || {};
        const regional_company = values.regional_company.find(company => company.regional_company_uuid === business_unit.regional_company_uuid) || {};
        const parent_company = values.parent_company.find(company => company.parent_company_uuid === regional_company.parent_company_uuid) || {};
        return {
            ...site,
            ...business_unit,
            ...regional_company,
            ...parent_company,
            default_supplier: default_supplier,
        }
    }


    /* -------------------------------------------------------------------------- */
    /*                               Site Hierarchy                               */
    /* -------------------------------------------------------------------------- */

    /**
     * Gets an entity's parent.
     * @param {*} entity 
     * @returns 
     */
    // const getParentEntity = (entity) => {
    //     const entityType = entityConfig.getType(entity);
    //     switch (entityType) {
    //         case entityConfig.type.regional_company:
    //             return parent_company.find(pc => pc.parent_company_uuid === entity.parent_company_uuid);
    //         case entityConfig.type.business_unit:
    //             return regional_company.find(rc => rc.regional_company_uuid === entity.regional_company_uuid);
    //         case entityConfig.type.business_unit_contact:
    //             return business_unit.find(bu => bu.business_unit_uuid === entity.business_unit_uuid);
    //         case entityConfig.type.site:
    //             return business_unit.find(bu => bu.business_unit_uuid === entity.business_unit_uuid);
    //         case entityConfig.type.site_contact:
    //             return site.find(s => s.site_uuid === entity.site_uuid);
    //         default:
    //             return undefined;
    //     }
    // }
    /**
     * Gets downstream entities based on the given entityType.
     * - Parent Company
     * - Regional Company
     * - Business Unit
     * - Site
     * @param {*} entity 
     * @returns 
     */
    // const getChildUuids = (entity) => {
    //     const entityType = entityConfig.getType(entity);
    //     let result = [entityConfig.getUuid(entity)];

    //     if (entityType === entityConfig.type.parent_company) {
    //         regional_company
    //             .filter(r => r.parent_company_uuid === entity.parent_company_uuid)
    //             .forEach(r => result.push(...getChildUuids(r)))

    //     } else if (entityType === entityConfig.type.regional_company) {
    //         business_unit
    //             .filter(b => b.regional_company_uuid === entity.regional_company_uuid)
    //             .forEach(b => result.push(...getChildUuids(b)))

    //     } else if (entityType === entityConfig.type.business_unit) {
    //         site
    //             .filter(s => s.business_unit_uuid === entity.business_unit_uuid)
    //             .forEach(s => result.push(...getChildUuids(s)))
    //     }
    //     return result;
    // }

    /**
     * Get's all parent UUIDs for the given entity
     * @param {*} entity 
     * @returns {[parent_uuid, ..., entity_uuid]} Array of all nested UUIDs
     */
    // const getParentUuids = (entity) => {
    //     const parentEntity = getParentEntity(entity);
    //     if (!parentEntity) {
    //         return [entityConfig.getUuid(entity)];
    //     } else {
    //         return getParentUuids(parentEntity).concat(entityConfig.getUuid(entity));
    //     }
    // }

    /**
     * Look up by a value
     * @param {*} lookupType 
     * @param {*} value 
     * @returns 
     */
    const lookupByValue = (lookupType, value) => {
        return values[lookupType].find(lookup => lookup.value === value) || {};
    };

    return <LookupContext.Provider
        children={props.children}
        value={{
            config: lookupConfig,
            isLoading: isLoading,
            byTypeUuid: lookupByUuid,
            byTypeKey: lookupByKey,
            byValue: lookupByValue,
            getSiteView: getSiteView,
            values: values,
        }}
    />
}

export const useLookupContext = () => useContext(LookupContext);