/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import MTextField from './MTextField';

MFormTextField.propTypes = {
    GridProps: PropTypes.object,
    control: PropTypes.object.isRequired,
    sx: PropTypes.object,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    validate: PropTypes.func,
    error: PropTypes.string,
    minRows: PropTypes.number,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onControlEnter: PropTypes.func,
    normalize: PropTypes.oneOf(["phone", "email", "currency", "integer"])
}

/**
 * 
 * @param {{
 *     GridProps: {},
 *     control: any,
 *     sx: {},
 *     name: string,
 *     label: string,
 *     multiline: bool,
 *     validate: (newValue: string) => string | undefined,
 *     minRows: number,
 *     required: bool,
 *     required: bool,
 *     error: string,
 *     autoFocus: bool,
 *     onControlEnter: (...args: any[]) => void,
 *     normalize: string,
 * }} props
 * @returns 
 */
function MFormTextField({ control, name, ...props }) {

    const { field: { value, onChange, } } = useController({
        control,
        name,
    });

    return (<MTextField
        {...props}
        value={value}
        onChange={onChange}
    />);
};




export default MFormTextField