/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MListItemCustom, MList, MIconButton } from '../@material-extend';
import PropTypes from 'prop-types';
import { Paper, Grid } from '@mui/material';
import { PageSection } from '../templates';
import Restricted from '../Restricted';
import { permissions } from '../../configs/permissionConstants';
import { useLookupContext } from '../../contexts';

SupplierList.propTypes = {
  suppliers: PropTypes.array,
  navigateToSupplier: PropTypes.func,
}

function SupplierList(props) {

  const [items, setItems] = useState([]);
  const lookup = useLookupContext();

  useEffect(() => {
    setItems(props.suppliers.map(supplier => {
      return {
        id: supplier.id,
        left: {
          title: supplier.supplier_name,
          subtitles:
            [
              (`Supplier ERP ID: ${supplier?.erp_supplier_id || "-"}`),
              (`3rd Party?: ${supplier.third_party ? "True" : "False"}`),
              (`Supplier UOM: ${lookup.byValue("unit_of_measure", supplier?.unit_of_measure)?.label || "-"}`),
              (`Supplier Currency: ${lookup.byValue("currency", supplier?.currency_code)?.value || "-"}`),
              (`Supplier Language: ${lookup.byValue("language", supplier?.language_used)?.label || "-"}`),
            ]
        },
        right: {
          children: <Grid container >
            <Restricted to={[permissions.WRITE_SUPPLIER_DETAILS]}>
              <MIconButton
                size="small"
                tooltip="Edit selected supplier"
                onClick={() => editClick(supplier.supplier_uuid)}
                icon="edit"
                color="primary"
              />
            </Restricted>
          </Grid>
        }
      }
    }))
  }, [props.suppliers]);


  const editClick = (supplier_uuid) => {
    props.navigateToSupplier(supplier_uuid)
  }

  return (
    <PageSection
    >
      <MList
        dense
        divider
        items={items.length > 0 ? items : [{
          disableButton: true,
          sx: { p: 2 },
          left: { title: "No suppliers", },
        }]}
        component={item => <MListItemCustom
          component={Paper}
          elevation={3}
          GridProps={{ sx: { mb: 2, p: 2 } }}
          {...item}
          sx={{
            py: 1,
            px: 2,
            ...item.sx,
            bgcolor: "theme.background.paper",
            color: "theme.text.primary",
            // borderRadius: 8,
          }}
        />
        }
      />
    </PageSection>
  );
}

export default SupplierList;