/* eslint-disable react-hooks/exhaustive-deps */
import createEntityContext from '../helpers/createEntityContext';

const EntityContext = createEntityContext({
    entityType: "subcool_event",
    defaultEntity: {
        site_id: 12345,
        subcool_event_end: 0,
        subcool_usage: 0,
        subcool_duration_minutes: 0,
        target_pressure: 0,
        pressure_reached: 0,
        subcool_did_not_complete: false,
        subcool_count: 0,
        update_history: null,
        updated_subcool_usage: null
    },
});

export const SubcoolEventContext = EntityContext.Context;
export const SubcoolEventContextProvider = EntityContext.Provider;
export const useSubcoolEventContext = EntityContext.useContext;