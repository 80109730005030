/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

import PageSection from '../../../templates/PageSection';
import { MButton, MIconButton, MSelect } from '../../../@material-extend';
import { Grid } from '@mui/material';
import { useNavigateTo } from '../../../../helpers';
import { routeConfig } from '../../../../configs';
import {
  useLookupContext,
  SupplierContractContext,
  useSupplierContractRateContext,
  useTakeOrPayTermContext,
} from '../../../../contexts';
import MDividerSection from '../../../@material-extend/MDividerSection';
import PageText from '../../../templates/PageText';
import SupplierContractRateList from '../../../lists/SupplierContractRateList';
import TakeOrPayTermList from '../../../lists/TakeOrPayTermList';
import Restricted from '../../../Restricted';
import { permissions } from '../../../../configs/permissionConstants';
import { confirmDialog } from '../../../ConfirmDialog';


/**
 * @param {{
 *    isLoading: boolean;
 * }} props
 * @returns 
 */
export default function SiteSupplierContractSection({ isLoading }) {

  const [params, navigateTo] = useNavigateTo();
  const lookup = useLookupContext();
  const supplierContractContext = useContext(SupplierContractContext);
  const supplierContractRateContext = useSupplierContractRateContext();
  const takeOrPayTermContext = useTakeOrPayTermContext();
  const [siteSupplierContracts, setSiteSupplierContracts] = useState([]);
  const [activeSupplierContract, setActiveSupplierContract] = useState(supplierContractContext.defaultValues);
  const [activeSupplierContractRates, setActiveSupplierContractRates] = useState([]);
  const [activeTakeOrPayTerms, setActiveTakeOrPayTerms] = useState([]);
  const [selectedContractId, setSelectedContractId] = useState('');
  const [isLoadingContractData, setIsLoadingContractData] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const handleAddContract = () => {
    navigateTo(routeConfig.supplier_contract_add);
  }

  const handleEditSupplierContract = () => {
    navigateTo(routeConfig.supplier_contract_edit, { supplier_contract_uuid: selectedContractId })
  }

  const handleAddTakeOrPayTerm = () => {
    navigateTo(routeConfig.supplier_contract_add_take_or_pay_term, { supplier_contract_uuid: selectedContractId })
  }

  const handleDeleteTakeOrPayTerm = async (data) => {
    await takeOrPayTermContext.deleteEntity(data.uuid)
      .then(() => {
        fetchSupplierContracts(params.site_uuid);
        setIsLoadingContractData(false);
      })
  }

  const handleAddSupplierContractRate = () => {
    navigateTo(routeConfig.supplier_contract_add_rate, { supplier_contract_uuid: selectedContractId })
  }

  const handleDeleteRate = async (data) => {
    await supplierContractRateContext.deleteEntity(data.uuid)
      .then(() => {
        fetchSupplierContracts(params.site_uuid);
        setIsLoadingContractData(false);
      })
  }

  const handleSelectContract = (newId) => {
    setSelectedContractId(newId);
    setIsLoadingContractData(true);
    const nextActiveContract = siteSupplierContracts.find(contract => contract.value === newId);
    if (nextActiveContract) {
      setActiveSupplierContract(nextActiveContract);
    }
    if (!newId) {
      return;
    }

    // Fetch the lists for the latest contract
    takeOrPayTermContext.queryHandler.fetch(`/${newId}`)
      .then(res => {
        setActiveTakeOrPayTerms(res.take_or_pay_term);
        setIsLoadingContractData(false);
      });
    supplierContractRateContext.queryHandler.fetch(`/${newId}`)
      .then(res => {
        const contractRates = res.supplier_contract_rate;
        const sortedRates = contractRates.sort((a, b) => new Date(b.supplier_contract_rate_effective_date) - new Date(a.supplier_contract_rate_effective_date));
        setActiveSupplierContractRates(sortedRates);
      });
  }

  const handleDeleteSupplierContract = async () => {
    setIsLoadingContractData(true);
    const deleteTakeorPayTermPromises = activeTakeOrPayTerms.map(take => takeOrPayTermContext.deleteEntity(take.take_or_pay_term_uuid));
    const deleteRatePromises = activeSupplierContractRates.map(rate => supplierContractRateContext.deleteEntity(rate.supplier_contract_rate_uuid));

    await Promise.allSettled([...deleteTakeorPayTermPromises, ...deleteRatePromises]).then(() => {
      supplierContractContext.deleteEntity(selectedContractId)
        .then(() => {
          fetchSupplierContracts(params.site_uuid);
          setIsLoadingContractData(false);
        })
    })
  }

  const fetchSupplierContracts = (site_uuid) => {
    supplierContractContext.queryHandler.fetch(`/${site_uuid}`).then(res => {
      const siteContracts = res.supplier_contract;
      // sort from recent to oldest where recent is the first element
      const sortedContracts = siteContracts.sort((a, b) => new Date(b.supplier_contract_execution_date) - new Date(a.supplier_contract_execution_date));

      setSiteSupplierContracts(sortedContracts.map((supplierContract, index) => {
        const result = {
          ...supplierContract,
          label: supplierContract.supplier_contract_execution_date,
          id: supplierContract.supplier_contract_uuid,
          value: supplierContract.supplier_contract_uuid,
        };
        return result;
      }));

    })
    .catch(error => {
      const errorMessage = "Unable to get Supplier Contract: " + error 
      console.warn(errorMessage);
    });
  }

  // fetch supplier contracts on mount
  useEffect(() => {
    fetchSupplierContracts(params.site_uuid);
  }, [params.site_uuid]);

  // set a new active supplier contract whenever supplier contracts load
  useEffect(() => {
    if (siteSupplierContracts.length > 0) {
      handleSelectContract(siteSupplierContracts[0].supplier_contract_uuid);
    } else {
      setActiveSupplierContractRates([]);
      setActiveTakeOrPayTerms([]);
      setActiveSupplierContract(supplierContractContext.defaultValues);
    }
  }, [siteSupplierContracts])

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  }

  return (<PageSection
    title="Supplier Contract"
    omitContainer
    spacing={2}
    endActions={
      <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACTS]}>
        <MButton
          GridProps
          collapseXs
          disabled={isLoading}
          size="small"
          icon="add"
          variant="outlined"
          color="primary"
          tooltip={{ title: "New Supplier Contract" }}
          onClick={handleAddContract}
          children="Add Contract"
        />
      </Restricted>
    }
    collapsible
  >
    {/* ------ Contract Info Section -------- */}

    {(isLoadingContractData || siteSupplierContracts.length > 0)
      && <>
        <MDividerSection
          position='list-first'
          direction='row'
          variant='section'
          above={{
            title: "Contract",
          }} >
          <PageText
            label="Contract Description"
            GridProps={{ xs: 12, md: 6 }}
            isLoading={isLoadingContractData}
            body={activeSupplierContract?.supplier_contract_description || "-"}
          />
          <Grid container item xs={12} md={6} alignItems="center" justifyContent="flex-start">
            <MSelect
              label="Execution Date"
              disabled={isLoadingContractData || siteSupplierContracts.length === 0}
              value={selectedContractId}
              onChange={e => handleSelectContract(e.target.value)}
              GridProps={{ xs: 7, }}
              options={siteSupplierContracts}
            />

                <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACTS, permissions.WRITE_SUPPLIER_CONTRACT_ACCOUNT_NUMBER]}>
                  <MIconButton
                    size="small"
                    tooltip="Edit selected contract"
                    onClick={() => handleEditSupplierContract()}
                    icon="edit"
                    color="primary"
                  />
                </Restricted>
                <Restricted to={[permissions.DELETE_SUPPLIER_CONTRACTS]}>
                  <MIconButton
                    size="small"
                    tooltip="Delete selected contract"
                    onClick={() => {
                      confirmDialog("Do you want to delete the supplier contract? It will also delete all the associated rates and terms.", () => handleDeleteSupplierContract())
                    }}
                    icon="delete"
                    color="primary"
                  />
                </Restricted>
          </Grid>

          <PageText
            label="Contract Status"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={lookup.byValue("supplier_contract_status", activeSupplierContract?.supplier_contract_status).label || "-"}
          />
          <PageText
            label="Supplier"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={lookup.byValue("supplier", activeSupplierContract?.supplier_uuid).label || "-"}
          />
          <PageText
            label="Contract Number"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeSupplierContract?.supplier_contract_number || "-"}
          />
          <PageText
            label="Account Number"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeSupplierContract?.account_number || "-"}
          />
          <PageText
            label="First Fill Date"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeSupplierContract?.first_fill_date || "-"}
          />
          <PageText
            label="Contract Length (months)"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeSupplierContract?.supplier_contract_term_length || "-"}
          />
          <PageText
            label="Contract End Date"
            GridProps={{ xs: 6, md: 3 }}
            isLoading={isLoadingContractData}
            body={activeSupplierContract?.supplier_contract_end_date || "-"}
          />

        </MDividerSection>

        {/* ------ Contract Rate Section -------- */}
        <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACT_RATES, permissions.READ_SUPPLIER_CONTRACT_RATES]}>
          <MDividerSection
            GridProps={{ mt: 10 }}
            position='list-middle'
            direction='row'
            variant='section'
          >
            <Grid container item xs={12} md={12} mb={1} alignItems="right" justifyContent="space-between">
              <Typography
                align="left"
                children={"Contract Rate"}
                variant={"h5"}
                sx={{ color: "secondary.dark" }}
              />
              <Restricted to={[permissions.WRITE_SUPPLIER_CONTACT_RATES]}>
                <MButton
                  GridProps
                  collapseXs
                  disabled={isLoading}
                  size="small"
                  icon="add"
                  variant="outlined"
                  color="primary"
                  tooltip={{ title: "New Contract Rate" }}
                  onClick={handleAddSupplierContractRate}
                  children="Add Rate"
                />
              </Restricted>
            </Grid>
            <FormGroup>
                <FormControlLabel sx={{ pl: 3 }}
                  label={showAll ? "Filter effective rates" : "Show all rates"}
                  control={<Switch
                    color="primary"
                    checked={showAll}
                    onChange={handleToggleShowAll}
                  />}
                />
              </FormGroup>
          </MDividerSection>
          {!isLoadingContractData && activeSupplierContractRates &&
            <Grid
              item
              xs={12}
              md={12}
              container
              direction="column"
              sx={{ px: 1 }}
            >
              <SupplierContractRateList
                items={activeSupplierContractRates}
                handleDeleteRate={handleDeleteRate}
                showEffective={!showAll}
              />
            </Grid>
          }
        </Restricted>

        {/* ------ Take or Pay Term Section -------- */}
        <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACT_TAKE_OR_PAY_TERMS, permissions.READ_SUPPLIER_CONTRACT_TAKE_OR_PAY_TERMS]}>
          <MDividerSection
            GridProps={{ mt: 10 }}
            position='list-middle'
            direction='row'
            variant='section'
          >
            <Grid container item xs={12} md={12} mb={2} alignItems="right" justifyContent="space-between">
              <Typography
                align="left"
                children={"Take or Pay Term"}
                variant={"h5"}
                sx={{ color: "secondary.dark" }}
              />
              <Restricted to={[permissions.WRITE_SUPPLIER_CONTRACT_TAKE_OR_PAY_TERMS]}>
                <MButton
                  GridProps
                  collapseXs
                  disabled={isLoading}
                  size="small"
                  icon="add"
                  variant="outlined"
                  color="primary"
                  tooltip={{ title: "New Take or Pay Term" }}
                  onClick={handleAddTakeOrPayTerm}
                  children="Add Take or Pay Term"
                />
              </Restricted>
            </Grid>
          </MDividerSection>
          {!isLoadingContractData && activeTakeOrPayTerms &&
            <Grid
              item
              xs={12}
              md={12}
              container
              direction="column"
              sx={{ px: 1 }}
            >
              <TakeOrPayTermList
                items={activeTakeOrPayTerms}
                handleDeleteTakeOrPayTerm={handleDeleteTakeOrPayTerm}
              />
            </Grid>
          }
        </Restricted>
      </>
    }
  </PageSection>
  )
}