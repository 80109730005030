/* eslint-disable react-hooks/exhaustive-deps */
import { Box, ButtonGroup, Checkbox, Grid, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import RefreshIcon from '@mui/icons-material/Refresh';
import Search from '@mui/icons-material/Search';
import { MAutoComplete, MButton, MDatePicker, MFormGroup } from '../../@material-extend';
import { LayoutContext, LookupContext, TankDeliveryEventContext } from '../../../contexts';
import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { dateHelper } from '../../../helpers/dateHelper';
import CheckedIcon from '../../CheckedIcon';
import { useNavigateTo } from '../../../helpers';
import { useSearchParams } from 'react-router-dom';
import { StringParser } from '../../../helpers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box sx={{ width: 20, }} />
      <GridToolbarExport
        csvOptions={{
          fileName: 'TankFills',
          fields: ['site_uuid', 'supplier_name', 'jira_ticket_number',
            'tank_delivery_end_timestamp', 'tank_delivery_duration',
            'tank_delivery_quantity', 'pod_quantity', 'billable_quantity',
            'manual_entry', 'tank_delivery_event_active', 'fill_quantity_reviewed',
            'hot_fill_t1', 'tank_level_delta', 'current_unit_per_ai_t1', 'new_ai',
            'tank_calibration_date_t1', 'tank_delivery_calibration_note'],
          utf8WithBom: true,
        }}
        printOptions={{
          disableToolbarButton: true
        }}
      />
      <Box sx={{ width: 100, }} />
    </GridToolbarContainer>
  );
}

export default function TankDeliveryTable() {
  /* ---------------------------- Navigation Hooks ---------------------------- */
  const [searchParams, setSearchParams] = useSearchParams();
  const [, navigateTo] = useNavigateTo();

  /* ----------------------------- Context Providers ----------------------------- */
  const lookup = useContext(LookupContext);
  const {
    windowDimensions: { height },
    constants,
    setPageState,
    pageState,
    isLoading,
    handleError,
  } = useContext(LayoutContext);
  const {
    entities,
    queryHandler
  } = useContext(TankDeliveryEventContext);

  /**
   * Site Name Comparator
   * useCallback to prevent this from getting redefined on every rerender
   */
  const siteNameComparator = React.useCallback((id1, id2) =>
    lookup.byValue('site_hierarchy', id1).label.localeCompare(lookup.byValue('site_hierarchy', id2).label), [lookup]);


  /* --------------------------- React State Values --------------------------- */

  const [items, setItems] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'tank_delivery_end_timestamp',
      sort: 'desc',
    },
  ]);
  const [startDateProps, setStartDateProps] = useState({
    minDate: undefined,
    maxDate: dateHelper.getToday(),
  });
  const [endDateProps, setEndDateProps] = useState({
    minDate: queryHandler.values.start_date || dateHelper.getToday(),
    maxDate: dateHelper.getBackDays(dateHelper.getToday(), -1),
  });

  /* -------------------------- Memoized State Values ------------------------- */

  /** Memoized options for the site dropdown */
  const options = React.useMemo(() => {
    if (!lookup?.values?.site_hierarchy.length) return []
    return lookup.values.site_hierarchy.map(site => ({ label: site.label, id: site.site_uuid }))
  }, [lookup.values.site_hierarchy])

  /** Memoized columns */
  const columns = React.useMemo(() => [
    {
      field: "actions",
      disableColumnMenu: true,
      disableReorder: true,
      headerName: '',
      widthMultiplier: 3,

      renderCell: (params) => (<ButtonGroup
        color="inherit"
      >
        <MButton
          onClick={() => navigateTo(routeConfig.tank_fill_event_view, { tank_delivery_event_uuid: params.id })}
          size="small"
          aria-label="view"
          children="View Event"
          tooltip="View Tank Fill Event"
        />

      </ButtonGroup>

      ),
    },
    {
      field: 'site_uuid',
      description: 'Site',
      headerName: 'Site',
      hide: false,
      widthMultiplier: 6,
      sortComparator: siteNameComparator,
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return `${lookup.byValue('site_hierarchy', value).label}`;
      },
      renderCell: (params) => {
        var site_name = lookup.byValue('site_hierarchy', params.value).label
        if (typeof site_name === "undefined") {
          site_name = 'Retired'
        }
        return (
          site_name
        )
      },
    },
    {
      field: 'supplier_name',
      description: 'Supplier Name',
      headerName: 'Supplier',
      widthMultiplier: 2,
    },
    {
      field: 'jira_ticket_number',
      description: 'Jira Ticket Number',
      headerName: 'Jira Ticket',
      widthMultiplier: 2,
    },
    {
      field: 'tank_delivery_end_timestamp',
      description: 'Delivery End Date',
      headerName: 'End Date',
      widthMultiplier: 4,
      renderCell: (params) => {
        return (
          dateHelper.formatLocalDateTime(params.value)
        )
      },
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return `${dateHelper.formatLocalDateTime(value)}`;
      },
    },
    {
      field: 'tank_delivery_duration',
      headerName: 'Duration',
      widthMultiplier: 1.5,
    },
    {
      field: 'tank_delivery_quantity',
      description: 'Delivery Quantity',
      headerName: 'Delivery Qty',
      widthMultiplier: 2,
    },
    {
      field: 'pod_quantity',
      description: 'POD Quantity',
      headerName: 'POD Qty',
      widthMultiplier: 2,
    },
    {
      field: 'billable_quantity',
      description: 'Billable Quantity',
      headerName: 'Billable Qty',
      widthMultiplier: 2,
    },
    {
      field: 'manual_entry',
      description: 'Manual Entry',
      headerName: 'Manual',
      widthMultiplier: 1.5,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },
    {
      field: 'tank_delivery_event_active',
      description: 'Active',
      headerName: 'Active',
      widthMultiplier: 1.5,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },
    {
      field: 'fill_quantity_reviewed',
      description: 'Quantity Reviewed',
      headerName: 'Qty Reviewed',
      widthMultiplier: 2.5,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },
    {
      field: 'hot_fill_t1',
      description: 'Hot Fill Tank 1',
      headerName: 'Hot Fill',
      widthMultiplier: 1.5,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },
    {
      field: 'tank_level_delta',
      headerName: 'Level Delta',
      widthMultiplier: 2.0,
    },
    {
      field: 'current_unit_per_ai_t1',
      headerName: 'Unit/AI',
      widthMultiplier: 1.5,
    },
    {
      field: 'new_ai',
      headerName: 'Suggested AI',
      widthMultiplier: 2.0,
    },
    {
      field: 'tank_calibration_date_t1',
      headerName: 'Calibrated',
      widthMultiplier: 2.0,
    },
    {
      field: 'tank_delivery_calibration_note',
      headerName: 'Note',
      widthMultiplier: 8.0,
    },
  ].map(fullCol => {
    const { widthMultiplier, ...col } = fullCol;
    return {
      ...col,
      width: 50 * widthMultiplier,
    }
  }), [lookup, siteNameComparator]);


  /* ----------------------- Use Effect Lifecycle Hooks ----------------------- */

  /** Map entity values */
  React.useEffect(() => {
    const extended = entities.map(e => ({ ...e,
      new_ai: suggestedAI(e.pod_quantity, e.post_fill_ai_t1, e.pre_fill_ai_t1),
      tank_level_delta: manual_tank_level_delta(e.tank_level_end_manual_t1, e.tank_level_begin_manual_t1)
    }));
    setItems(extended);
  }, [entities]);

  /** Fetch data on initial load */
  React.useEffect(() => {
    queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    queryHandler.values.start_date = dateHelper.getStartDateFromUrlOrDefault(searchParams)
    queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)
    queryHandler.fetch().then(res => {
      setSortModel([{ field: 'tank_delivery_end_timestamp', sort: 'desc' }]);
    }, setSortModel)
    .catch(error => {
      const errorMessage = "Unable to get Tank Fills: " + error 
      handleError(errorMessage);
    });
    setPageState({ ...pageState, dense: true })
    return () => {
      setPageState({
        ...pageState,
        dense: false
      });
    };
  }, [])

  /** Update the date range based on the query params and update URL params */
  React.useEffect(() => {
    setStartDateProps(prev => ({
      ...prev,
      maxDate: queryHandler.values.end_date || dateHelper.getToday(),
    }))
    setEndDateProps(prev => ({
      ...prev,
      minDate: queryHandler.values.start_date || dateHelper.getToday(),
    }))
    setSearchParams({
      ...searchParams,
      site_uuid: queryHandler.values?.site_uuid || "",
      start_date: queryHandler.values.start_date || dateHelper.getToday(),
      end_date: queryHandler.values.end_date || dateHelper.getToday()
    })
  }, [queryHandler.values.start_date, queryHandler.values.end_date, queryHandler.values.site_uuid])


  const refreshData = () => {
    // TODO: can this be done with useLocation() ??
    setTimeout(() => {
      navigateTo(routeConfig.tank_fill_table_refresh)
    });
    setTimeout(() => {
      navigateTo(routeConfig.tank_fill_table)
    });
  }

  function suggestedAI(pod_quantity, post_fill_ai, pre_fill_ai) {
    if ((!pod_quantity) || (!post_fill_ai) || (!pre_fill_ai)) {
      return "";
    }
    return StringParser.formatNumerical(pod_quantity / (post_fill_ai - pre_fill_ai), { decimalPlaces: 3 });
  }

  function manual_tank_level_delta(tank_level_end_manual, tank_level_begin_manual) {
    if ((!tank_level_end_manual) || (!tank_level_begin_manual)) {
      return "-";
    }
    return parseInt(tank_level_end_manual) - parseInt(tank_level_begin_manual);
  }

  const applyFilter = () => {
    queryHandler.fetch()
    .then(result => {
      return result;
    })
    .catch(error => {
      const errorMessage = "Unable to get Tank Fills: " + error 
      handleError(errorMessage);
    });
  }

  return (
    <Page
      hideHeader
      fullWidth
    >
      {/* ----------------------------- Filter Section ---------------------------- */}
      <PageSection
        title={"Manage Tank Fills"}
        direction="row"
        headerSx={{ mt: 1 }}
        onBack={() => navigateTo(routeConfig.dashboard)}
        onBackTooltip={"Back to Dashboard"}
        endActions={
          <Restricted to={[permissions.WRITE_TANK_FILLS]}>
            <MButton
              sx={{ mr: 1 }}
              startIcon={<AddCircleIcon />}
              variant="outlined"
              collapseXs
              onClick={() => navigateTo(routeConfig.tank_fill_manual_entry)}
              children="Manual Entry"
            />
          </Restricted>
        }
      >
        <Grid container
          item
          spacing={5}
          sx={{ pr: 1 }}
          xs={12}
          md={6}
          lg={8}
        >
          {/* ------------------------------ Date Filter ------------------------------ */}
          {/* ------------------------------ Date Filter ------------------------------ */}
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 4, }}
            title={"Date Range"}
          >
            <MDatePicker
              {...startDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values.start_date}
              label={"Start Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("start_date")}
            />
            <MDatePicker
              {...endDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values["end_date"]}
              placeholder="End Date"
              label={"End Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("end_date")}
            />
          </MFormGroup>

          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 5, }}
            title={"Site"}
          >
            <MAutoComplete
              GridProps={{
                xs: 12,
                lg: 12
              }}
              options={options}
              value={queryHandler.values["site_uuid"]}
              onChange={queryHandler.createValueChangeHandler("site_uuid")}
              includeAll
              name="site_uuid"
            />
          </MFormGroup>
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 3, lg: 1, }}
            title={"Review"}
          >
            <Tooltip title="Show only fills that require review" arrow>
              <Checkbox label="review"
                sx={{ mt: 4, ml: 5 }}
                GridProps={{
                  xs: 12,
                  md: 12,
                  lg: 12,
                }}
                name="review_only"
                tooltip="Show only fills that require review"
                // checked={queryHandler.values.review_only}
                onChange={queryHandler.createValueChangeHandler("review_only")}
              />
            </Tooltip>
          </MFormGroup>
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 3, lg: 1 }}
            title={"Inactive"}
          >
            <Tooltip title="Include fills that are inactive" arrow>
              <Checkbox label="inactive"
                sx={{ mt: 4, ml: 5 }}
                GridProps={{
                  xs: 12,
                  md: 12,
                  lg: 12,
                }}
                name="include_inactive"
                // checked={queryHandler.values.include_inactive}
                onChange={queryHandler.createValueChangeHandler("include_inactive")}
              />
            </Tooltip>
          </MFormGroup>


        </Grid>
        {/* ------------------------------ Action Items ------------------------------ */}

        <MFormGroup
          GridProps={{ xs: 12, md: 6, lg: 4, spacing: 1, }}
          labelSx={{ mb: 1 }}
          title={`Actions`}

        >

          <MButton
            GridProps={{ xs: 6, md: 8, }}
            sx={{ mt: 1, }}
            variant="contained"
            size={"large"}
            color="primary"
            onClick={applyFilter}
            children="Apply Filter"
            startIcon={<Search />}
            disabled={!queryHandler.isDirty}
          />
          <MButton
            GridProps={{
              xs: 6,
              md: 4,
            }}
            sx={{ mt: 0.6, }}
            size={"large"}
            color="primary"
            onClick={refreshData}
            children="Refresh Data"
            startIcon={<RefreshIcon />}
            tooltip="Refresh the data"
          />
        </MFormGroup>
      </PageSection>
      <div style={{
        display: 'flex',
        width: "100%",
        overflowX: "auto",
        height: height - constants.APP_BAR_HEIGHT
      }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={!!isLoading}
            rows={items}
            columns={columns}
            columnBuffer={25}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={setPageSize}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            slots={{
              toolbar: CustomToolbar,
            }}
          />

        </div>
      </div>
    </Page >
  );
}