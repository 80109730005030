import { StringParser } from "../helpers";

const entityTypes = {
  parent_company: "parent_company",
  regional_company: "regional_company",
  business_unit: "business_unit",
  site: "site",
  site_contact: "site_contact",
  business_unit_contact: "business_unit_contact",
  supplier: "supplier",
  nitrocrete_representative: "nitrocrete_representative",
  nitrogen_delivery: "nitrogen_delivery",
  pricing_rate: "pricing_rate",
  pricing_minimum: "pricing_minimum",
  pricing_fee: "pricing_fee",
  take_or_pay_term: "take_or_pay_term",
  usage_event: "usage_event",
  subcool_event: "subcool_event",
  representative_assignment: "representative_assignment",
  tank_delivery_event: "tank_delivery_event",
  delivery_ticket: "delivery_ticket",
  customer_contract: "customer_contract",
  supplier_contract: "supplier_contract",
  supplier_contract_rate: "supplier_contract_rate",

};

/* -------------------------------------------------------------------------- */
/*                                Entity Types                                */
/* -------------------------------------------------------------------------- */
/**
 * Short entity keys
 */
export const entityConfig = {
  type: entityTypes,

  /**
   * 
   * @param {keyof typeof entityTypes} entityType 
   * @param {bool} plural 
   * @returns 
   */
  getDisplayName: (entityType, plural = false) => {
    switch (entityType) {
      case entityConfig.type.parent_company: return plural ? "Parent Companies" : "Parent Company";
      case entityConfig.type.regional_company: return plural ? "Regional Companies" : "Regional Company";
      case entityConfig.type.business_unit: return plural ? "Business Units" : "Business Unit";
      case entityConfig.type.site: return plural ? "Sites" : "Site";
      case entityConfig.type.site_contact: return plural ? "Site contacts" : "Site contact";
      case entityConfig.type.business_unit_contact: return plural ? "Business Unit contacts" : "Business Unit contact";
      case entityConfig.type.supplier: return plural ? "Suppliers " : "Supplier";
      case entityConfig.type.nitrocrete_representative: return plural ? "Nitrocrete Representatives" : "Nitrocrete Representative";
      case entityConfig.type.nitrogen_delivery: return plural ? "Nitrogen deliveries" : "Nitrogen delivery";
      case entityConfig.type.usage_event: return plural ? "usage events" : "usage event";
      case entityConfig.type.subcool_event: return plural ? "subcool events" : "subcool event";
      case entityConfig.type.representative_assignment: return plural ? "Representative Assignments" : "Representative Assignment";
      case entityConfig.type.tank_delivery_event: return plural ? "Tank Fill Events" : "Tank Fill Event";
      case entityConfig.type.delivery_ticket: return plural ? "Delivery Tickets" : "Delivery Ticket";
      case entityConfig.type.customer_contract: return plural ? "Customer Contracts" : "Customer Contract";
      case entityConfig.type.supplier_contract: return plural ? "Supplier Contracts" : "Supplier Contract";

      default: return plural ? StringParser.readingCase(entityType) + "s" : StringParser.readingCase(entityType);
    }
  },
  /**
   * 
   * @param {keyof typeof entityTypes} entityType 
   * @param {bool} plural 
   * @returns 
   */
  getChildDisplayName: (entityType, plural = false) => {
    switch (entityType) {
      case entityConfig.type.parent_company: return plural ? "Regional Companies" : "Regional Company";
      case entityConfig.type.regional_company: return plural ? "Business Units" : "Business Unit";
      case entityConfig.type.business_unit: return plural ? "Business Sites" : "Business Site";
      default: return plural ? "entities" : "entity";
    }
  },
  /**
   * Developer reference for accessing response from API.
   * @param {string} entityType 
   * @returns 
   */
  getApiResponseReferenceKey: (entityType) => {
    switch (entityType) {
      case entityConfig.type.parent_company: return "parent_company";
      case entityConfig.type.regional_company: return "regional_company";
      case entityConfig.type.business_unit: return "business_unit";
      case entityConfig.type.site: return "site";
      case entityConfig.type.site_contact: return "site_contact";
      case entityConfig.type.business_unit_contact: return "business_unit_contact";
      case entityConfig.type.supplier: return "supplier";
      case entityConfig.type.nitrogen_delivery: return "nitrogen_delivery";
      case entityConfig.type.nitrocrete_representative: return "nitrocrete_representative";
      case entityConfig.type.usage_event: return "usage_event";
      case entityConfig.type.subcool_event: return "subcool_event";
      case entityConfig.type.representative_assignment: return "representative_assignment";
      case entityConfig.type.tank_delivery_event: return "tank_delivery_event";
      case entityConfig.type.delivery_ticket: return "delivery_ticket";
      case entityConfig.type.customer_contract: return "customer_contract";
      case entityConfig.type.supplier_contract: return "supplier_contract";
      default: return entityType;
    }
  },

  /**
 * Get's the type based on which name property the entity has.
 * @param { any } entity 
 * @returns {keyof typeof entityTypes} EntityType
 */
  getType: (entity) => {
    const entityKeys = Object.keys(entity);

    if (entityKeys.includes("parent_company_name")) {
      return entityConfig.type.parent_company;
    } else if (entityKeys.includes("regional_company_name")) {
      return entityConfig.type.regional_company;
    } else if (entityKeys.includes("business_unit_name")) {
      return entityConfig.type.business_unit;
    } else if (entityKeys.includes("site_name")) {
      return entityConfig.type.site;
    } else if (entityKeys.includes("site_contact_first_name")) {
      return entityConfig.type.site_contact;
    } else if (entityKeys.includes("business_unit_contact_first_name")) {
      return entityConfig.type.business_unit_contact;
    } else if (entityKeys.includes("tank_delivery_event_uuid")) {
      return entityConfig.type.tank_delivery_event;
    } else if (entityKeys.includes("delivery_ticket_uuid")) {
      return entityConfig.type.delivery_ticket;
    } else if (entityKeys.includes("supplier_uuid") && entityKeys.includes("erp_supplier_id")) {
      return entityConfig.type.supplier;
    } else if (entityKeys.includes("nitrocrete_representative") && entityKeys.includes("nitrocrete_representative_active")) {
      return entityConfig.type.nitrocrete_representative;
    } else if (entityKeys.includes("nitrogen_delivery_uuid")) {
      return entityConfig.type.nitrogen_delivery;
    } else if (entityKeys.includes("usage_event_uuid")) {
      return entityConfig.type.usage_event;
    } else if (entityKeys.includes("subcool_event_uuid")) {
      return entityConfig.type.subcool_event;
    } else if (entityKeys.includes("pricing_rate_uuid") && entityKeys.includes("pricing_rate_description")) {
      return entityConfig.type.pricing_rate;
    } else if (entityKeys.includes("representative_assignment_uuid") || entityKeys.includes("representative_type")) {
      return entityConfig.type.representative_assignment;
    } else if (entityKeys.includes("pricing_fee_uuid")) {
      return entityConfig.type.pricing_fee;
    } else if (entityKeys.includes("customer_contract_uuid") && entityKeys.includes("customer_contract_effective_date")) {
      return entityConfig.type.customer_contract;
    } else if (entityKeys.includes("supplier_contract_uuid") && entityKeys.includes("supplier_contract_execution_date")) {
      return entityConfig.type.supplier_contract;
    } else if (entityKeys.includes("take_or_pay_term_uuid") && entityKeys.includes("take_or_pay_term_description")) {
      return entityConfig.type.take_or_pay_term;
    } else if (entityKeys.includes("pricing_minimum_uuid") && entityKeys.includes("pricing_minimum_description")) {
      return entityConfig.type.pricing_minimum;
    } else if (entityKeys.includes("supplier_contract_rate_uuid") && entityKeys.includes("supplier_contract_rate_effective_date")) {
      return entityConfig.type.supplier_contract_rate;
    } else if (entityKeys.includes("tank_delivery_begin_timestamp")) {
      return entityConfig.type.tank_delivery_event;
    } else {
      console.warn("Failed to infer entity type: ", entityKeys);
      return "";
    }
  },

  /**
   * Get's the Uuid based on which entity it is.
   * @param { any } entity 
   * @returns {EntityType.parent|EntityType.region|EntityType.business|EntityType.site} EntityType
   */
  getUuid: (entity) => {
    if (!!entity.id && typeof entity.id === "string") {
      return entity.id;
    }
    const entityType = entityConfig.getType(entity);

    switch (entityType) {
      case entityConfig.type.parent_company: return entity.parent_company_uuid;
      case entityConfig.type.regional_company: return entity.regional_company_uuid;
      case entityConfig.type.business_unit: return entity.business_unit_uuid;
      case entityConfig.type.site: return entity.site_uuid;
      case entityConfig.type.site_contact: return entity.site_contact_uuid;
      case entityConfig.type.business_unit_contact: return entity.business_unit_contact_uuid;
      case entityConfig.type.supplier: return entity.supplier_uuid;
      case entityConfig.type.nitrocrete_representative: return entity.nitrocrete_representative;
      case entityConfig.type.nitrogen_delivery: return entity.nitrogen_delivery_uuid;
      case entityConfig.type.usage_event: return entity.usage_event_uuid;
      case entityConfig.type.subcool_event: return entity.subcool_event_uuid;
      case entityConfig.type.representative_assignment: return entity.representative_assignment_uuid;
      case entityConfig.type.tank_delivery_event: return entity.tank_delivery_event_uuid;
      case entityConfig.type.delivery_ticket: return entity.delivery_ticket_uuid;
      case entityConfig.type.customer_contract: return entity.customer_contract;
      case entityConfig.type.supplier_contract: return entity.supplier_contract;
      default: return entity[`${entityType}_uuid`];
    }
  },
  /**
   * Remove and return the UUID Field from an object.
   * Also strips any other fields that are not needed to save an entity - (kinda hacky but easiest place to consolidate logic).
   * @param { any } entity 
   * @returns {EntityType.parent|EntityType.region|EntityType.business|EntityType.site} EntityType
   */
  removeUuid: (entity) => {
    let uuid = entity.id;
    const entityType = entityConfig.getType(entity);
    if (!entityType) {
      return "";
    }
    switch (entityType) {
      case entityConfig.type.parent_company:
        uuid = entity.parent_company_uuid;
        delete entity.parent_company_uuid;
        return uuid;
      case entityConfig.type.regional_company:
        uuid = entity.regional_company_uuid;
        delete entity.regional_company_uuid;
        return uuid;
      case entityConfig.type.business_unit:
        uuid = entity.business_unit_uuid;
        delete entity.business_unit_uuid;
        return uuid;
      case entityConfig.type.site:
        uuid = entity.site_uuid;
        delete entity.site_uuid;
        return uuid;
      case entityConfig.type.site_contact:
        uuid = entity.site_contact_uuid;
        delete entity.site_contact_uuid;
        return uuid;
      case entityConfig.type.business_unit_contact:
        uuid = entity.business_unit_contact_uuid;
        delete entity.business_unit_contact_uuid;
        return uuid;
      case entityConfig.type.supplier:
        uuid = entity.supplier_uuid;
        delete entity.supplier_uuid;
        return uuid;
      case entityConfig.type.nitrocrete_representative:
        uuid = entity.nitrocrete_representative;
        delete entity.nitrocrete_representative;
        return uuid;
      case entityConfig.type.nitrogen_delivery:
        uuid = entity.nitrogen_delivery_uuid;
        delete entity.nitrogen_delivery_uuid;
        return uuid;
      case entityConfig.type.usage_event:
        uuid = entity.usage_event_uuid;
        delete entity.usage_event_uuid;
        return uuid;
      case entityConfig.type.subcool_event:
        uuid = entity.subcool_event_uuid;
        delete entity.subcool_event_uuid;
        return uuid;
      case entityConfig.type.representative_assignment:
        uuid = entity.representative_assignment_uuid;
        delete entity.representative_assignment_uuid;
        return uuid;
      case entityConfig.type.customer_contract:
        uuid = entity.customer_contract_uuid;
        delete entity.customer_contract_uuid;
        return uuid;
      case entityConfig.type.supplier_contract:
        uuid = entity.supplier_contract_uuid;
        delete entity.supplier_contract_uuid;
        return uuid;
      case entityConfig.type.tank_delivery_event_uuid:
        uuid = entity.tank_delivery_event_uuid;
        delete entity.tank_delivery_event_uuid;
        return uuid;
      default:
        uuid = entity[`${entityType}_uuid`];
        delete entity[`${entityType}_uuid`];
        return uuid;
    }
  },

  /**
   * Maps the business contact's fields to a site contact.
   * @param {*} businessContact 
   */
  mapBusinessContactToSiteContact: (businessContact) => {
    return {
      site_contact_first_name: businessContact.business_unit_contact_first_name,
      site_contact_last_name: businessContact.business_unit_contact_last_name,
      site_contact_title: businessContact.business_unit_contact_title,
      site_contact_email: businessContact.business_unit_contact_email,
      site_contact_mobile_phone: businessContact.business_unit_contact_mobile_phone,
      site_contact_office_phone: businessContact.business_unit_contact_office_phone,
    }
  }
}